import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';


export const getComments = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.COMMENTS,
    data:{
      active: true
    }
  }
  const comments = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}))
    })
  })
  return comments;
}

export const deleteComment = (commentId, successMsg) => {
  const fetchSettings = {
    method: "DELETE",
    url: API_URLS.COMMENT_ACTION(commentId),
  }
  const resp = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then(()=>{
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
      resolve();
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
      reject(error);
    })
  })
  return resp;
}

export const createComment = (updateComment, successMsg) => {
  const fetchSettings = {
    method: "POST",
    url: API_URLS.COMMENT,
    data: updateComment,
    body: true
  }
  const comment = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
      reject(error);
    })
  })
  return comment;
}

export const updateComment = (commentId, comment, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.COMMENT_ACTION(commentId),
    data: comment,
    body: true
  }
  const newComment = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
      reject(error);
    })
  })
  return newComment;
}
