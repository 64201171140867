
import FormField from "@ingka/form-field";
import InputField from '@ingka/input-field';
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/focus/style.scss";
import '@ingka/forms/style.scss';


export default function Input({formField, input}) {
  return (
    <FormField
      {...formField}
    >
      <InputField
        {...input}
      />
    </FormField>
  )
}