import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalBody, ModalHeader } from '@ingka/modal';
import style from './createEditServices.module.scss';
import Input from '../ingka/Input';
import Select from './Select';
import Button from '../ingka/Button';
import Text from '../ingka/Text';
import CheckNum from "../../utils/checkNum";
import { SUPERADMIN } from '../../utils/roles';

const CreateEditUser = ({ visible, title, rolesOptions, paymentTypeOptions, userSelect, onClose, onUpdateUser }) => {

  const [t] = useTranslation('global');
  const nickName = userSelect?.nickName;
  const name = userSelect?.name;
  const [role, setRole] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [minLimit, setMinLimit] = useState(userSelect.minLimit ? userSelect.minLimit : '');
  const [maxLimit, setMaxLimit] = useState(userSelect.maxLimit ? userSelect.maxLimit : '');
  const [disabledSave, setDisabledSave] = useState(false);

  useEffect(() => {
    if (userSelect) {
      const role = { label: userSelect.roleName, value: userSelect.roleId };
      setRole(role);
      if(userSelect.paymentType){
        const paymentType = { label: userSelect.paymentType?.name, value: userSelect.paymentType?.id };
        setPaymentType(paymentType);
      }
      
    };
  }, [userSelect])

  useEffect(() => {
    if (minLimit !== '' && maxLimit !== '') {
      setDisabledSave(false);
      return;
    }
    setDisabledSave(true);
  }, [role, minLimit, maxLimit, paymentType])

  const save = () => {
    const userUpdate = {
      maxLimit: maxLimit,
      minLimit: minLimit,
      roleId: role.value,
      paymentTypeId: paymentType?.value
    };
    onUpdateUser(userUpdate);
    onClose();
  }

  return (
    <Modal
      visible={visible}
      focusLockProps={{
        disabled: false
      }}
      handleCloseBtn={onClose}
    >
      <Sheets
        aria-label='Accessibility header for a modal'
        header={<ModalHeader className={style.modal_header} title={title} />}
      >
        <ModalBody className={style.modal_body}>
        <div>
              <Text tagName="h3" headingSize="s" className="mt-0 mb-0">{t('users.user')}</Text>
              <Text tagName="p" bodySize="m" className="mt-0" qa="user-nickname">{nickName}</Text>
            </div>
            <div>
              <Text tagName="h3" headingSize="s" className="mt-0 mb-0">{t('global.name')}</Text>
              <Text tagName="p" bodySize="m" className="mt-0" qa="user-name">{name}</Text>
            </div>
          <form id='userForm' className='row' autoComplete="off">
            <Select
              formField={{ className: 'col-12' }}
              label={t('users.profile')}
              onChange={val => setRole(val)}
              defaultValue={role}
              value={role}
              isSearchable={false}
              qa='role'
              name='role'
              options={rolesOptions}
              isDisabled={userSelect.roleId === SUPERADMIN}
            />
            <Input
              formField={{
                className: 'col-6'
              }}
              input={{
                label: t('users.minLimit'),
                id:'minLimit',
                value: minLimit,
                state: 'string',
                type:'text',
                onChange: e => setMinLimit(e.target.value),
                onKeyDown: (e) => (CheckNum(e)),
              }}
            />
            <Input
              formField={{
                className: 'col-6'
              }}
              input={{
                label: t('users.maxLimit'),
                id:'maxLimit',
                value: maxLimit,
                state: 'string',
                type:'text',
                onChange: e => setMaxLimit(e.target.value),
                onKeyDown: (e) => (CheckNum(e)),
              }}
            />
            <Select
              formField={{ className: 'col-12' }}
              label={t('users.paymentMethod')}
              onChange={val => setPaymentType(val)}
              defaultValue={paymentType}
              value={paymentType}
              isSearchable={false}
              qa='paymentType'
              name='paymentType'
              options={paymentTypeOptions}
            />
          </form>
        </ModalBody>
        <div className={style.submit}>
          <Button
            type='emphasised'
            text={t('global.save')}
            onClick={save}
            disabled={disabledSave}
            qa="btn-save-user"
          />
        </div>
      </Sheets>
    </Modal>
  )
}

export default CreateEditUser;