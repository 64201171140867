import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeNeedReload, closeBill, createMsgToast } from "../../store/globalSlice";
import style from "./bill.module.scss"
import CardInfoBill from "./CardInfoBill";
import BillActions from "./BillActions";
import FormBill from "./FormBill";
import Button from "../ingka/Button";
import { getBill, saveBill } from '../../services/billServices';
import Load from "../ingka/Load";
import { STATE } from "../../utils/billStates";
import { BILLER } from "../../utils/roles";
import Text from "../ingka/Text";

export default function Bill({billId, isOpen}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user)
  const rol = useSelector((state) => state.global.rol.id)

  const[modalOpen, setModalOpen] = useState(false)
  const[bill, setBill] = useState(null)
  const[loading, setLoading] = useState(false)
  const[messageError, setMessageError] = useState(null)

  useEffect(()=>{
    setModalOpen(isOpen)
    if(isOpen && user){
      goToGetBill()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen, billId, user])

  const goToGetBill = (bill = billId) => {
    setLoading(true)
    getBill({billId: bill, user:user.nickName}).then( bill => {
      setBill(bill)
    }).finally(()=>setLoading(false))
  }

  const closeModal = (closeAndReload = true) => {
    setModalOpen(false)
    dispatch(closeBill(closeAndReload))
  }

  const goToSaveBill = (bill) => {
    setMessageError(null)
    if(bill.state.id === STATE.CLOSE && bill.checkAmount !== "0.00" && bill.checkAmount !== "-0.00"){
      setMessageError(t('bill.checkAmountIsNotZero'))
      return
    }
    const buttonType = window.event.submitter.name

    setLoading(true)
    saveBill({billId: bill.id, data: bill}).then( () =>{
      if(buttonType === "saveAndExit"){
        closeModal(true)
      } else {
        goToGetBill(null)
      }
      
      dispatch(createMsgToast({body: t('bill.successSave'), variant:"positive"}))
    })
  }

  const reloadModalAndBills = () => {
    goToGetBill()
    dispatch(changeNeedReload(true))
  }

  return (
    <Modal
      visible={modalOpen}
      focusLockProps={{
        disabled: false
      }}
      handleCloseBtn={()=> closeModal(false)}
      qa="modalBill"
      >
      <Sheets
        size="large"
        aria-label="Accessibility header for a modal"
        header={
        <ModalHeader 
          className={style.modal_header} 
          title={t('bill.bill')}
        />}
      >
        <ModalBody>
          {loading &&
          <Load />
          }
          {!loading && bill &&
            <section className="row">
              <aside className="col-lg-4 col-md-12">
                <CardInfoBill data={bill}/>
                {rol !== BILLER &&
                (bill.state.id === STATE.CLOSE || bill.state.id === STATE.AVAILABLE) &&
                  <BillActions 
                    billsSelected={[bill.id]} 
                    bills={[bill]} 
                    isMulti={false} 
                    reloadPage={(userId) => userId?.toLowerCase()  === user.nickName?.toLowerCase()  ? reloadModalAndBills() : closeModal()}
                  />
                }
                {bill.state.id !== STATE.CLOSE && 
                bill.state.id !== STATE.INCIDENCE && 
                bill.user?.toLowerCase() === user.nickName?.toLowerCase()  &&
                  <>
                  {messageError &&
                    <Text tagName="p" bodySize="s" className="text-red">{messageError}</Text>
                  }
                    <Button
                      type="emphasised"
                      text={t('bill.saveAndContinue')}
                      className="col-12 mt-2"
                      form="billForm"
                      htmlType="submit"
                      name="saveAndContinue"
                    />
                    <Button
                      type="secondary"
                      text={t('bill.saveAndExit')}
                      className="col-12 mt-2"
                      form="billForm"
                      htmlType="submit"
                      name="saveAndExit"
                    />
                  </>
                }
              </aside>
              <article className="col-lg-8 col-md-12">
                <FormBill rawBill={bill} rol={rol} user={user} goToSaveBill={goToSaveBill}/>
              </article>
            </section>
          }
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
