import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalBody, ModalHeader } from '@ingka/modal';
import style from './createEditServices.module.scss';
import Input from '../ingka/Input';
import Select from './Select';
import Button from '../ingka/Button';

const CreateEditServices = ({ visible, title, typeOptions, servicesSelect, onClose, onSaveService }) => {

  const [t] = useTranslation('global');
  const [code, setCode] = useState(servicesSelect?.code || '');
  const [name, setName] = useState(servicesSelect?.name || '');
  const [type, setType] = useState(null);
  const [disabledSave, setDisabledSave] = useState(true);

  useEffect(() => {
    if (servicesSelect) {
      const option = { label: servicesSelect.typeService.name, value: servicesSelect.typeService.id };
      setType(option);
    };
  }, [servicesSelect])

  useEffect(() => {
    if (code && name && type) {
      setDisabledSave(false);
      return;
    }
    setDisabledSave(true);
  }, [code, name, type])

  const save = () => {
    const service = {
      code: code,
      name: name,
      typeServiceId: type.value
    };
    onSaveService(service);
    onClose();
  }

  return (
    <Modal
      visible={visible}
      focusLockProps={{
        disabled: false
      }}
      handleCloseBtn={onClose}
    >
      <Sheets
        aria-label='Accessibility header for a modal'
        header={<ModalHeader className={style.modal_header} title={title} />}
      >
        <ModalBody className={style.modal_body}>
          <form id='serviceForm' className='row'>
            <Input
              formField={{
                className: 'col-12'
              }}
              input={{
                label: t('services.code'),
                id:'code',
                value:code,
                type:'text',
                onChange: e => setCode(e.target.value),
              }}
            />
            <Input
              formField={{
                className: 'col-12'
              }}
              input={{
                label: t('global.name'),
                id:'name',
                value:name,
                type:'text',
                onChange: e => setName(e.target.value),
              }}
            />
            <Select
              formField={{ className: 'col-12' }}
              label={t('services.type')}
              onChange={val => setType(val)}
              defaultValue={type}
              value={type}
              isSearchable={false}
              qa='type'
              name='type'
              options={typeOptions}
            />
          </form>
        </ModalBody>
        <div className={style.submit}>
          <Button
            type='emphasised'
            text={t('global.save')}
            onClick={save}
            disabled={disabledSave}
            qa='btn-save-service'
          />
        </div>
      </Sheets>
    </Modal>
  )
}

export default CreateEditServices;