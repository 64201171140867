export default function CheckNum(e){
  if (e.keyCode === 190 || 
    e.keyCode === 8  || 
    //(e.keyCode === 189 && e.target.value === "")  ||
    e.keyCode === 189  ||
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 96 && e.keyCode <= 105) ||
    e.keyCode === 109) {
    return false
  }else{
    return e.preventDefault()
  }
}