import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import Button from "../components/ingka/Button";
import { useEffect, useState, Fragment } from "react";
import Load from "../components/ingka/Load";
import Table from '../components/global/Table';
import { getImportsLogs, uploadFile } from "../services/importServices";
import UploadFile from '../components/global/UploadFile';
import { importStatus } from '../utils/importStatus';

export default function Import() {
  const [t] = useTranslation("global");
  const [modalOpen, setModalOpen] = useState(false);
  const [imports, setImports] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ rowCount: "10", pageCount: "1" });
  const [importingFile, setImportingFile] = useState(null);
  const DEFAULT_SIZE_ROWS = 15;
  const columns = [
    { accessorKey: "date", header: t('import.dateUpload'),
      Cell: ({ cell }) => <span>{cell.getValue().replace('T', ' ').slice(0,19)}</span>,
    },
    { accessorKey: "status", header: t('global.state') },
    { accessorKey: "nameFile", header: t('import.fileName') },
    { accessorKey: "user", header: t('global.user') },
    { accessorKey: "uploadedLines", header: t('import.linesUpload') }
  ];

  useEffect(() => {
    goToGetImports();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToGetImports = (pageIndex = 0) => {
    setLoading(true);
    getImportsLogs({ params: { page: pageIndex, size: DEFAULT_SIZE_ROWS } }).then((imports) => {
      setImportingFile(firstItemInProgress(imports.content));
      setImports(imports.content);
      setPageInfo({ rowCount: imports.totalElements, pageCount: imports.totalPages, pageSize: DEFAULT_SIZE_ROWS, pageIndex: pageIndex });
    }
    ).finally(() => setLoading(false))
  }

  const firstItemInProgress = (imports) => {
    if (imports.length === 0) {
      return false;
    }
    return imports[0].status === importStatus.IN_PROGRESS;
  }

  const goToImport = (file) => {
    const data = new FormData();
    if (file) data.append('file', file);
    uploadFile(data);
    setModalOpen(!modalOpen);
    goToGetImports();
  }

  return (
    <Fragment>
      <div className="container-header-button">
        <Text tagName="h2" headingSize="m">{t('menu.importFile')}</Text>
        <Button
          qa="buttonImportFile"
          type="emphasised"
          text={t("import.uploadFile")}
          onClick={() => setModalOpen(!modalOpen)}
          className=""
          disabled={importingFile}
        />
      </div>
      {loading &&
        <Load />
      }
      {!loading && imports &&
        <Table
          columns={columns}
          data={imports}
          localization={{
            noRecordsToDisplay: t('import.noImports')
          }}
          short={{ id: 'date', desc: true }}
          enablePagination={true}
          pageChange={(pageIndex) => goToGetImports(pageIndex)}
          pageInfo={pageInfo}
          paintLines={false}
          highlightFirstLine={importingFile}
          renderDetailPanel={({ row }) =>
            row.original.errors !== null ? (
              <ul>
                {row.original.errors.map( (error, index) => (
                    <li key={index}>{error}</li>
                  ))
                }
              </ul>
            ) : null
          }
        />
      }
      <UploadFile
        visible={modalOpen}
        title={t('import.uploadFile')}
        onSaveFile={goToImport}
        onClose={() => {
          setModalOpen(!modalOpen);
          }}
      ></UploadFile>
    </Fragment>
  )
}
