import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import style from './select.module.scss'
import { useTranslation } from 'react-i18next';

export default function Sel({
  formField, 
  label, 
  onChange, 
  value, 
  ref, 
  name,
  qa="select",
  options, 
  isSearchable=false,
  isClearable=false,
  defaultValue=false, 
  placeholder, 
  closeMenuOnSelect, 
  isMulti,
  isLoading=false,
  isDisabled=false,
  readOnly=false
  }) {
  const animatedComponents = makeAnimated();
  const [t] = useTranslation("global")
  placeholder = t('global.select')+'...'
  return (
    <section 
      qa={qa}
      className={`${formField.className} label-wrapper form-field ${isDisabled ? 'select-disabled': ''}`}
      >
      <label className={style.label}>{label}</label>
      <Select
        placeholder={placeholder || t('components.select.selectMultiple') }
        className={`${!formField.shouldValidate ? style.select : style.selectError} ${readOnly ? 'select-read-only' : ''}`}
        closeMenuOnSelect={closeMenuOnSelect || true}
        components={animatedComponents}
        isSearchable={readOnly ? false : isSearchable}
        options={options}
        ref={ref}
        onChange={onChange}
        value={value}
        name={name}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isLoading={isLoading}
        isDisabled={isDisabled}
        menuIsOpen={readOnly ? false : undefined}
        isClearable={readOnly ? false : isClearable}
      />
      {formField.shouldValidate &&
        <div className="form-field__content">
        <span id={formField.validation.id} className={`helper-text helper-text--${formField.validation.type}`}>
          {formField.validation.msg}
        </span>
      </div>
      }
      
    </section>
    
  )
}
