import { useTranslation } from 'react-i18next';
import { Fragment, useState, useEffect } from 'react';
import { getUsers, updateUsers } from './../services/usersService';
import { getPaymentTypes } from './../services/paymentTypeService';
import { getRoles } from './../services/roleService';
import Text from '../components/ingka/Text';
import Load from './../components/ingka/Load'
import Table from '../components/global/Table';
import Button from '../components/ingka/Button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import CreateEditUser from '../components/global/CreateEditUser';

export default function Users() {
  const [t] = useTranslation('global');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [userSelect, setUserSelect] = useState(null);
  const [modalUser, setModalUser] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(null);
  const [rolesOptions, setRolesOptions] = useState(null)
  const columns = [
    { accessorKey: "nickName", header: t('users.user'), sortable: true },
    { accessorKey: "name", header: t('global.name'), sortable: true },
    { accessorKey: "roleName", header: t('users.profile') , sortable: true },
    {
      accessorKey: "minLimit", header: t('users.limit'), Cell: ({ row }) => (
        <span>{`De ${row.original.minLimit} a ${row.original.maxLimit}`}</span>
      )
    },
    { accessorKey: "paymentType", header: t('users.paymentMethod'), sortable: true, Cell: ({ row }) => (
      <span>{row.original.paymentType ?row.original.paymentType.name : t('users.allPayments')}</span>
    )
    }
  ];

  useEffect(() => {
    getAllUsers();
  }, [])

  const getAllUsers = () => {
    getUsers().then(users => {
      setUsers(users);
    }).finally(() => setLoading(false))
  }

  const goEdit = (user) => {
    if(rolesOptions === null) getRolesOptions();
    if(paymentTypeOptions === null) getPaymentTypeOptions();
    
    setUserSelect(user);
    setModalUser(!modalUser);
  }

  const getPaymentTypeOptions = () => {
    getPaymentTypes().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      formatOptions.unshift({label: t('users.allPayments'), value: null})
      setPaymentTypeOptions(formatOptions);
    }).finally(() => setLoading(false))
  }

  const getRolesOptions = () => {
    getRoles().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setRolesOptions(formatOptions);
    }).finally(() => setLoading(false))
  }

  const updateUser = (user) => {
    updateUsers(userSelect.id, user, t("users.successEdit")).then((updateUser) => {
      setUsers((updateUsers) => {
        return updateUsers.map((user) => {
          return user.id === updateUser.id ? updateUser : user;
        });
      });
    })
  }

  return (
    <Fragment>
      <Text tagName="h2" headingSize="m">{t('users.title')}</Text>
      {loading &&
        <Load />
      }
      {!loading && columns && users &&
        <Table
          columns={columns}
          data={users}
          localization={{
            noRecordsToDisplay: t('users.noUsers')
          }}
          enableSorting={true}
          enablePagination={false}
          enableRowActions={true}
          renderRowActions={({ row }) => (
            <section className="justify-content-end">
              <Button
                className="table-icon-action"
                onClick={() => goEdit(row.original)}
                type="tertiary"
                iconOnly
                ssrIcon={pencil}
                small
                qa="btn-edit-user"
              />
            </section>
          )
          }
        />
      }
      {!loading && modalUser &&
        <CreateEditUser
          visible={modalUser}
          title={t('users.edit')}
          paymentTypeOptions={paymentTypeOptions}
          rolesOptions={rolesOptions}
          userSelect={userSelect}
          onUpdateUser={updateUser}
          onClose={() => {
            setModalUser(!modalUser);
            setUserSelect(null);
          }}
        />
      }
    </Fragment>
  )
}
