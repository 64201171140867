import React, { useState } from 'react'
import Message from "../ingka/Message";
import Text from "../ingka/Text";
import Button from '../ingka/Button';
import iconCopy from '@ingka/ssr-icon/paths/copy';
import { useTranslation } from 'react-i18next';

export default function CardInfoBill({data}) {
  const [t] = useTranslation("global")
  const [iconOnly, setIconOnly] = useState(true)

  const copy = () => {
    setIconOnly(false)
    navigator.clipboard.writeText(data.externalReference3)
    setTimeout(() => {
      setIconOnly(true)
    },2000)
  }

  return (
    <Message
    qa="cardInfoBill"
      variant="informative"
      title={
        <div style={{fontWeight: 400}}>
          <div style={{display: "flex"}}>
            <div style={{marginRight: "1rem"}}>
              <Text headingSize="s" tagName="h3" className="m0">SAMS</Text>
              <Text bodySize="m" tagName="p" className="mt-0">{data.externalReference3}</Text>
            </div>
            <Button 
              type="tertiary"
              small
              text={t('bill.samsIsCopy')}
              iconOnly={iconOnly}
              ssrIcon={iconCopy}
              onClick={() => copy()}
              title={t('bill.copySams')}
            />
          </div>
          
          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('global.amount')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.homeValue} €</Text>
          </div>

          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('bills.payMode')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.paymentType?.name}</Text>
          </div>

          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('global.user')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.user === null ? t('bill.noUser') : data.user}</Text>
          </div>

          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('global.state')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.state.name}</Text>
          </div>

          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('global.type')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.homeValue < 0 ? t('bill.saleType') : t('bill.returnType')}</Text>
          </div>

          <div>
            <Text headingSize="s" tagName="h3" className="mb-0">{t('global.description')}</Text>
            <Text bodySize="m" tagName="p" className="mt-0">{data.lineDescription}</Text>
          </div>
          {data.repeatedSams.length > 0 &&
            <>
              <Text headingSize="s" tagName="h4" className="mb-0 text-blue">{t('bill.repeatSams')}</Text>
              <ol className="text-blue">
                {data.repeatedSams.map( bill => 
                  <li key={bill.id}>
                    <Text 
                    bodySize="l" 
                    tagName="p" 
                    className="mt-0 text-blue">
                      <b>{bill.homeValue} € </b>: {bill.state.name}
                    </Text>
                  </li>
                )}
              </ol>
            </>
          }
        </div>
      }
    />
  )
}
