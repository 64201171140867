export const API_URLS = {
  PAYMENT_METHODS: 'paymentMethods',
  ROLES: 'roles',
  USER_UPDATE: (userId) => `user/${userId}`,
  USERS: 'users',
  COMMENT: 'typeComment',
  COMMENT_ACTION: (commentId) => `typeComment/${commentId}`,
  COMMENTS: 'typesComments/active',
  LOGS: 'logs',

  //INVOICES
  INVOICES: 'invoices',
  INVOICE_BY_USER:'invoice-by-user',
  INVOICES_BY_USER:'invoices-by-user',
  SUMMARY: 'invoices/summary-by-status',
  INVOICES_DYNAMIC: 'invoices/dynamic',
  ACTIONS:(action) => `invoice/${action}-invoice`,
  INVOICE_BY_BILL: (billId) => `invoice/${billId}`,
  TYPE_ORDERS: 'typesOrders',
  SAVE_INVOICE: (billId) => `invoice/${billId}`,
  EXPORT: 'file/generate-report-invoices',
  PAYMENT_TYPES: 'paymentTypes',

  STATE: (stateId) => `state/${stateId}`,
  STATES: 'states',
  SERVICE: 'service',
  SERVICE_ACTION: (userId) => `service/${userId}`,
  SERVICES: 'services/active',
  TOTAL_SUMMARY: 'invoices/total-sum-by-status-id',
  TYPES_SERVICES: 'typesServices',
  UPLOAD_EXCEL: 'invoices/uploadExcel',

  AUTH: 'authentication/1.0/authSSO',
  CHECK_USER: 'user/search-register'
};
