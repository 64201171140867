import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getComments, deleteComment, createComment, updateComment } from '../services/commentsService';
import { getStates } from '../services/statesService';
import Text from "../components/ingka/Text";
import Table from '../components/global/Table';
import Button from "../components/ingka/Button";
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Load from './../components/ingka/Load';
import AlertConfirm from '../components/global/AlertConfirm';
import CreateEditComment from '../components/global/CreateEditComment';

export default function Comments() {
  const [t] = useTranslation('global')
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commentSelect, setCommentSelect] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const [stateOptions, setStateOptions] = useState(false);
  const columns = [
    { accessorKey: "state.name", header: t('comments.state') },
    { accessorKey: "title", header: t('global.name') },
    { accessorKey: "description", header: t('comments.comment') }
  ]

  useEffect(() => {
    getAllComments();
  }, [t])

  const getAllComments = () => {
    getComments().then(comments => {
      setComments(comments)
    }).finally(() => setLoading(false))
  }

  const goCreate = () => {
    getStateOptions();
    setModalComment(!modalComment);
  }

  const goEdit = (comment) => {
    setLoading(true);
    getStateOptions();
    setCommentSelect(comment);
    setModalComment(!modalComment);
  }

  const getStateOptions = () => {
    getStates().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setStateOptions(formatOptions);
    }).finally(() => setLoading(false))
  }

  const onModalDelete = (comment) => {
    setCommentSelect(comment);
    setModalDelete(!modalDelete);
  }

  const onDeleteComment = () => {
    setModalDelete(!modalDelete);
    deleteComment(commentSelect.id, t('comments.successDelete')).then(() => {
      setComments(comments.filter(service => service.id !== commentSelect.id));
      setCommentSelect(null);
    })
  }

  const saveComment = (comment) => {
    if (commentSelect) {
      update(comment);
      return;
    }
    create(comment);
  }

  const create = (comment) => {
    createComment(comment, t("comments.successCreate")).then((newComment) => {
      setComments([...comments, newComment]);
    })
  }

  const update = (comment) => {
    updateComment(commentSelect.id, comment, t("comments.successEdit")).then((commentEdit) => {
      setComments((updateComments) => {
        return updateComments.map((comment) => {
          return comment.id === commentEdit.id ? commentEdit : comment;
        })
      });
    })
  }

  return (
    <Fragment>
      <section className='container-header-button'>
      <Text tagName="h2" headingSize="m">{t('comments.title')}</Text>
        <Button text={t('comments.create')} type="emphasised" onClick={goCreate} qa='btn-create-comment'/>
      </section>
      {loading &&
        <Load />
      }
      {!loading && columns && comments &&
        <Table
          columns={columns}
          data={comments}
          localization={{
            noRecordsToDisplay: t('comments.noComments')
          }}
          enableSorting={false}
          enablePagination={false}
          enableRowActions={true}
          renderRowActions={({ row }) => (
            <section className="justify-content-end">
              <Button
                className="table-icon-action"
                onClick={() => goEdit(row.original)}
                type="tertiary"
                iconOnly
                ssrIcon={pencil}
                small
                qa="btn-edit-comment"
              />
              <Button
                className="table-icon-action"
                onClick={() => onModalDelete(row.original)}
                type="tertiary"
                iconOnly
                ssrIcon={trashCan}
                small
                qa="btn-delete-comment"
              />
            </section>
          )
          }
        />
      }
      {!loading && modalComment &&
        <CreateEditComment
          visible={modalComment}
          title={commentSelect ? t('comments.edit') : t('comments.create')}
          stateOptions={stateOptions}
          commentSelect={commentSelect}
          onSaveComment={saveComment}
          onClose={() => {
            setModalComment(!modalComment);
            setCommentSelect(null);
          }}
        />
      }
      {modalDelete &&
        <AlertConfirm
          visible={modalDelete}
          title={`${t('global.delete')} ${t('comments.comment').toLowerCase()}`}
          message={t('bills.msgWarningAction')}
          confirmButtonText={t('global.delete')}
          onClose={() => {
            setModalDelete(!modalDelete);
            setCommentSelect(null);
          }}
          onConfirm={onDeleteComment}
        />
      }
    </Fragment>
  )
}
