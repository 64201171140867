import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalBody, ModalHeader } from '@ingka/modal';
import style from './createEditServices.module.scss';
import Input from '../ingka/Input';
import Select from './Select';
import Button from '../ingka/Button';

const CreateEditComment = ({ visible, title, stateOptions, commentSelect, onClose, onSaveComment }) => {

    const [t] = useTranslation('global');
    const [name, setName] = useState(commentSelect?.title || '');
    const [state, setState] = useState(null);
    const [comment, setComment] = useState(commentSelect?.description || '');
    const [disabledSave, setDisabledSave] = useState(true);
  
    useEffect(() => {
      if (commentSelect) {
        const option = { label: commentSelect.state.name, value: commentSelect.state.id };
        setState(option);
      };
    }, [commentSelect])
  
    useEffect(() => {
      if (comment && name && state) {
        setDisabledSave(false);
        return;
      }
      setDisabledSave(true);
    }, [name, state, comment])
  
    const save = () => {
      const commentSave = {
        description: comment,
        title: name,
        stateId: state.value
      };
      onSaveComment(commentSave);
      onClose();
    }
  
    return (
      <Modal
        visible={visible}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={onClose}
      >
        <Sheets
          aria-label='Accessibility header for a modal'
          header={<ModalHeader className={style.modal_header} title={title} />}
        >
          <ModalBody className={style.modal_body}>
            <form id='serviceForm' className='row'>
              <Input
                formField={{
                  className: 'col-12'
                }}
                input={{
                  label: t('global.name'),
                  id:'name',
                  value: name,
                  state: 'number',
                  type:'text',
                  onChange: e => setName(e.target.value),
                }}
              />
              <Select
                formField={{ className: 'col-12' }}
                label={t('comments.state')}
                onChange={val => setState(val)}
                defaultValue={state}
                value={state}
                isSearchable={false}
                qa='state'
                name='state'
                options={stateOptions}
              />
              <Input
                formField={{
                  className: 'col-12'
                }}
                input={{
                  label: t('comments.comment'),
                  id:'comment',
                  value: comment,
                  state: 'string',
                  type:'text',
                  onChange: e => setComment(e.target.value),
                }}
              />
            </form>
          </ModalBody>
          <div className={style.submit}>
            <Button
              type='emphasised'
              text={t('global.save')}
              onClick={save}
              disabled={disabledSave}
              qa='btn-save-comment'
            />
          </div>
        </Sheets>
      </Modal>
    )
  }
  
  export default CreateEditComment;