import { Route, Routes, useLocation } from 'react-router-dom'

import Header from '../components/global/Header';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updatePage, updatePath } from '../store/globalSlice';
import notFound from '../views/NotFound';
import Users from '../views/Users';
import Comments from '../views/Comments';
import Services from '../views/Services';
import Import from '../views/Import';
import Breakdown from '../views/Breakdown';
import Bills from '../views/Bills';
import States from '../views/States';

import Bill from '../components/global/Bill';

export default function PrivateRouter() {
  const dispatch = useDispatch();
  const bill = useSelector((state) => state.global.bill)
  const RoutesMaster = [
    {
      path: "/",
      Element: Bills
    },
    {
      path: "import",
      Element: Import
    },
    {
      path: "breakdown",
      Element: Breakdown
    },
    {
      path: "users",
      Element: Users
    },
    {
      path: "comments",
      Element: Comments
    },
    {
      path: "services",
      Element: Services
    },
    {
      path: "states",
      Element: States
    },
    {
      path: "not-found",
      Element: notFound
    },
  ]
  
  let location =  useLocation();
  let paths = location.pathname.split('/')
  let page = null;

  //set first path for page 
  if(paths.length > 1){
    page = paths[1]
  }

  //location change
  useEffect(() => {
    dispatch(updatePath(location.pathname))
    dispatch(updatePage(page === "" ? "bills" : page))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <header id="header">
        <Header/>
      </header>
      <main className="container">
        <Routes>
          {
            RoutesMaster.map(({path, Element}, index) => (
              <Route path={path} element={<Element />} key={index}/>
            ))
          }
        </Routes>
        <Bill billId={bill.billId} isOpen={bill.isOpen}/>
      </main>
    </>
  )
}
