import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getServices, deleteService, createService, updateService, servicesOptions } from '../services/servicesServices';
import Text from '../components/ingka/Text';
import Table from '../components/global/Table';
import Button from '../components/ingka/Button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import CreateEditServices from '../components/global/CreateEditServices';
import AlertConfirm from '../components/global/AlertConfirm';
import Load from './../components/ingka/Load'

export default function Services() {
  const [t] = useTranslation('global');
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState(null);
  const [servicesSelect, setServicesSelect] = useState(null);
  const [modalService, setModalService] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [typeOptions, setTypeOptions] = useState(null);
  const columns = [
    { accessorKey: "code", header: t('services.code') },
    { accessorKey: "name", header: t('global.name') },
    { accessorKey: "typeService.name", header: t('services.type') }
  ];

  useEffect(() => {
    getAllServices();
  }, [])

  const getAllServices = () => {
    getServices().then(services => {
      setServices(services);
    }).finally(() => setLoading(false))
  }

  const goCreateService = () => {
    setOptions();
    setModalService(!modalService);
  }

  const goEdit = (service) => {
    setLoading(true);
    setOptions();
    setServicesSelect(service);
    setModalService(!modalService);
  }

  const setOptions = () => {
    servicesOptions().then(options => {
      const formatOptions = options.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setTypeOptions(formatOptions);
    }).finally(() => setLoading(false))
  }

  const onModalDelete = (service) => {
    setServicesSelect(service);
    setModalDelete(!modalDelete);
  }

  const onDeleteService = () => {
    setModalDelete(!modalDelete);
    deleteService(servicesSelect.id, t('services.successDelete')).then(() => {
      setServices(services.filter(service => service.id !== servicesSelect.id));
      setServicesSelect(null);
    })
  }

  const saveService = (service) => {
    if (servicesSelect) {
      update(service);
      return;
    }
    create(service);
  }

  const create = (service) => {
    createService(service, t("services.successCreate")).then((newService) => {
      setServices([...services, newService]);
    })
  }

  const update = (service) => {
    updateService(servicesSelect.id, service, t("services.successEdit")).then((serviceEdit) => {
      setServices((updateServices) => {
        return updateServices.map((service) => {
          return service.id === serviceEdit.id ? serviceEdit : service;
        })
      });
    })
  }

  return (
    <Fragment>
      <section className='container-header-button'>
        <Text tagName="h2" headingSize="m">{t('services.title')}</Text>
        <Button text={t('services.createService')} type="emphasised" onClick={goCreateService} qa='btn-create-sevice'/>
      </section>
      {loading &&
        <Load />
      }
      {!loading && columns && services &&
        <Table
          columns={columns}
          data={services}
          localization={{
            noRecordsToDisplay: t('services.noServices')
          }}
          enableSorting={false}
          enablePagination={false}
          enableRowActions={true}
          renderRowActions={({ row }) => (
            <section className="justify-content-end">
              <Button
                className="table-icon-action"
                onClick={() => goEdit(row.original)}
                type="tertiary"
                iconOnly
                ssrIcon={pencil}
                small
                qa='btn-edit-sevice'
              />
              <Button
                className="table-icon-action"
                onClick={() => onModalDelete(row.original)}
                type="tertiary"
                iconOnly
                ssrIcon={trashCan}
                small
                qa='btn-delete-sevice'
              />
            </section>
          )
          }
        />
      }
      {!loading && modalService &&
        <CreateEditServices
          visible={modalService}
          title={servicesSelect ? t('services.editService') : t('services.createService')}
          typeOptions={typeOptions}
          servicesSelect={servicesSelect}
          onSaveService={saveService}
          onClose={() => {
            setModalService(!modalService);
            setServicesSelect(null);
          }}
        />
      }
      {modalDelete &&
        <AlertConfirm
          visible={modalDelete}
          title={`${t('global.delete')} ${t('services.service').toLowerCase()}`}
          message={t('bills.msgWarningAction')}
          confirmButtonText={t('global.delete')}
          onClose={() => {
            setModalDelete(!modalDelete);
            setServicesSelect(null);
          }}
          onConfirm={onDeleteService}
        />
      }

    </Fragment>
  )
}
