import { useTranslation } from "react-i18next"
import Button from "../ingka/Button"
import dataChart from '@ingka/ssr-icon/paths/data-chart'
import Modal, { Prompt, ModalHeader } from "@ingka/modal"
import { useState } from "react"
import List from "./List"
import style from "./billActions.module.scss"

export default function Summary({summary}) {
  const [t] = useTranslation("global")
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <div>
      <Button
        type="secondary"
        iconOnly
        small
        ssrIcon={dataChart}
        onClick={() => setModalOpen(!modalOpen)}
        title={t('bills.summary')}
        qa="buttonSummary"
      />
      <Modal
        visible={modalOpen}
        escapable={false}
        handleCloseBtn={() => setModalOpen(!modalOpen)}
        qa="resumeData"
      >
        <Prompt
          className={style.modal}
          header={<ModalHeader ariaCloseTxt={t('global.close')} />}
        >
          <div className="mb-3">
            <List
              header={{title:t('global.state'), amount: t('bills.casesNum')}}
              items={summary}
            />
          </div>
        </Prompt>
      </Modal>
    </div>
  )
}
