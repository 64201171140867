import style from './datePicker.module.scss'

export default function DatePicker({formField, label, onChange, value, inputRef, readOnly=false, id, name, qa="date"}) {
  return (
    <div className={`${formField.className} label-wrapper form-field`} qa={qa}>
    <label className={style.label}>{label}</label>
    <input 
      className={`${style.input} ${formField.shouldValidate ? style.error : ""} ${readOnly ? 'date-read-only': ''}`}
      type="date"
      value={value}
      onChange={onChange}
      ref={inputRef}
      readOnly={readOnly}
      id={id}
      name={name}
    />
    {formField.shouldValidate &&
      <div className="form-field__content">
      <span id={formField.validation.id} className={`helper-text helper-text--${formField.validation.type}`}>
        {formField.validation.msg}
      </span>
    </div>
    }
    
  </div>
  )
}
