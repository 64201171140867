import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';

export const getUsers = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.USERS,
  }
  const users = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return users;
}

export const updateUsers = (userId, userUpdate, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.USER_UPDATE(userId),
    data: userUpdate,
    body: true
  }
  const user = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
      reject(error);
    })
  })
  return user;
}