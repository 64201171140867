import { MaterialReactTable } from 'material-react-table'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default function Table({
  columns, 
  data,
  localization,
  short, 
  colPing,
  enableRowSelection=false,
  enableSorting=true, 
  enableRowActions=false, 
  enablePagination=false,
  pageChange,
  renderRowActions=false,
  renderDetailPanel=false,
  paintLines=true,
  highlightFirstLine=false,
  selectedRows,
  pageInfo={pageCount:0, rowCount:0, pageSize:10, pageIndex:0}},
  isDisabled=false,
  ) {
  
  const [t] = useTranslation("global")
  const [pagination, setPagination] = useState({
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
  });
  const [rowSelection, setRowSelection] = useState({}) 
  useEffect(()=>{
    //pass object to array
    if(selectedRows){
      selectedRows(Object.keys(rowSelection))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rowSelection])

  useDidMountEffect(() => {
    if(data.length > 0) pageChange(pagination.pageIndex)
  },[pagination]);

  return (
    <MaterialReactTable
      columns={columns} 
      data={data}
      localization={localization}
      enableRowSelection={enableRowSelection}
      getRowId={(row) => row.id}
      onRowSelectionChange={setRowSelection}
      state={{
        pagination, 
        rowSelection
      }}
      enableSorting={enableSorting}
      enableColumnActions={false}
      enableBottomToolbar={enablePagination}
      enableTopToolbar={false}
      enablePagination={enablePagination}
      muiTableBodyRowProps={{ hover: false }}
      enablePinning
      initialState={{
        density: 'compact',
        sorting: short === undefined ? [] : [{...short}],
        columnPinning: { left: colPing ? [colPing] : []}
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: "none"
        },
      }}

      muiTableBodyProps={{
        sx: {
          '& tr:nth-of-type(odd) > td': {
            backgroundColor: paintLines ? 'rgb(0, 124, 193, 0.08)' : '',
          },
          '& tr:first-of-type > td':{
            backgroundColor: highlightFirstLine ? 'rgb(0, 124, 193, 0.08)' : '',
          },
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          borderBottom: "2px solid rgb(223, 223, 223)",
        },
      }}
      muiTableBodyCellProps={({ row, cell }) => ({
          style: row.original.selected ? {backgroundColor: 'rgb(254 234 226)'}:{}
        })
      }
      enableRowActions={enableRowActions}
      positionActionsColumn="last"
      renderRowActions={renderRowActions}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: ''
        },
        'mrt-row-expand' : {
          header: t('global.errors')
        }
      }}
      muiPaginationProps= {{
        shape: 'rounded',
        showRowsPerPage: false,
        variant: 'outlined',
      }}
      paginationDisplayMode='pages'
      manualPagination={true}
      onPaginationChange={setPagination}
      rowCount={pageInfo.rowCount}
      pageCount={pageInfo.pageCount}
      renderDetailPanel={renderDetailPanel}
      positionExpandColumn="last"
      enableExpandAll={false}
      muiTableFooterCellProps = {{
        sx: {
          borderBottom: "none",
          backgroundColor: "rgb(0, 88, 163)"
        },
      }}
      isDisabled={isDisabled}
    />
  )
}
