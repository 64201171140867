import Select from "./Select";
import DatePicker from "./DatePicker";
import Input from "../ingka/Input";
import SSRIcon from "@ingka/ssr-icon";
import arrowOnPath from "@ingka/ssr-icon/paths/arrow-on-path";
import returnBtn from "@ingka/ssr-icon/paths/arrow-u-turn-backwards";
import Textarea from "../ingka/Textarea";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import style from "./formBill.module.scss"
import { Controller, useForm } from "react-hook-form";
import { STATE } from "../../utils/billStates";
import CheckNum from "../../utils/checkNum";
import { useDispatch, useSelector } from "react-redux";
import { getTypeOrders } from "../../services/billServices";
import { setTypeOrders } from "../../store/globalSlice";
import { getServices } from "../../services/servicesServices";
import { getComments } from "../../services/commentsService";
import ArrayToOption from "../../utils/arrayToOption";

export default function FormBill({rawBill, rol, user, goToSaveBill}) {
  const [t] = useTranslation("global")
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch()
  const typeOrders = useSelector((state) => state.global.typeOrders)
  const states = useSelector((state) => state.global.states)
  const SERVICE_TYPE = 1
  const SERVICE_ID = 1
  const COMPENSATION_ID = 2

  const[bill, setBill] = useState(null)
  const[readOnly, setReadOnly] = useState(false)
  const[orderTypeOptions, setOrderTypeOptions] = useState([])
  const[servicesOptions, setServicesOptions] = useState([])
  const[compensationCodeOptions, setCompensationCodeOptions] = useState([])
  const[stateOptions, setStateOptions] = useState([])
  const[allComments, setAllComments] = useState(null)
  const[commentsOptions, setCommentsOptions] = useState([])
  const today = new Date().toISOString().slice(0, 10)

  const goToGetTypeOrders = () => {
    getTypeOrders().then( types => {
      setOrderTypeOptions(types)
      dispatch(setTypeOrders(types))
    })
  }

  const goToGetComments = () => {
    getComments().then( comments => {
      setAllComments(ArrayToOption(comments, "id", "title", true))
    })
  }

  useEffect(() => {
    if(!readOnly){
      if(!typeOrders){
        goToGetTypeOrders()
      } else {
        setOrderTypeOptions(typeOrders)
      }
      goToGetComments()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[readOnly,typeOrders])

  const optionServices = (array) => {
    return array.map( option => ({
      label: `${option.code} - ${option.name}`, 
      value: option.id
    }))
  }
  const servicesSplit = () => {
    if(rawBill.services.length > 0){
      const services = rawBill.services.filter(ser => ser.typeService.id === SERVICE_ID)
      const compensations = rawBill.services.filter(ser => ser.typeService.id === COMPENSATION_ID)
      return{
        listServices:optionServices(services),
        listCompensations: optionServices(compensations)
      }
    }
    return {}
  }

  useEffect(() => {
    if(rawBill && user && rol){
      setBill({
        ...rawBill,
        ...(rawBill.state.id === STATE.RESERVED || rawBill.state.id === STATE.ASSIGNED ?
          {state: null} :
          {state: {label: rawBill.state.name, value: rawBill.state.id, requiredComment: rawBill.state.requiredComment}}
        ),
        ...(rawBill.endDate ? {endDate: rawBill.endDate.slice(0,10)} : {endDate: today}),
        ...(rawBill.typeOrder ? {typeOrder: {label: rawBill.typeOrder.name, value: rawBill.typeOrder.id}} : {}),
        ...servicesSplit(),
        ...(rawBill.comments.length > 0 ? {comments: ArrayToOption(rawBill.comments, "id", "title")} : {})
      })
      //not editable
      if(rawBill.state.id === STATE.CLOSE || 
        rawBill.state.id === STATE.INCIDENCE || 
        rawBill.user?.toLowerCase() !== user.nickName.toLowerCase() ||
        rawBill.state.id === STATE.PENDING){ 
        setReadOnly(true)
      } else {
        setReadOnly(false)
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rol,rawBill, user])

  useEffect(() => {
    if(states){
      const statesOpt = states.filter(state => state.id !== STATE.AVAILABLE && 
        state.id !== STATE.RESERVED && 
        state.id !== STATE.ASSIGNED && 
        state.id !== STATE.N546 &&
        state.id !== STATE.PENDING)
      setStateOptions(ArrayToOption(statesOpt, "id", "name", true))
    }
  },[states])

  const calcCheckAmount = () => {
    let calc = (
      (Number(bill.salesAmount ? bill.salesAmount : 0) - 
      Number(bill.returnAmount ? bill.returnAmount : 0) + 
      Number(bill.serviceAmount ? bill.serviceAmount : 0) +
      Number(bill.compensationAmount ? bill.compensationAmount : 0) +
      Number(bill.cashDifference ? bill.cashDifference : 0)) - 
      (bill.homeValue * -1)
    ).toFixed(2)

    if(calc === "-0.00") calc = "0.00"
    return calc
  }
  const calcCheckBill = () => {
    return ((Number(bill.rectifyingInvoiceAmount ? bill.rectifyingInvoiceAmount : 0) - 
    Number(bill.manualInvoiceAmount ? bill.manualInvoiceAmount : 0)) - 
    bill.homeValue).toFixed(2)
  }

  const goToGetServices = () => {
    getServices().then( allOptions => {
      let servicesOpt = []
      let compensationOpt = []
      allOptions.forEach( el => {
        if(el.typeService.id === SERVICE_TYPE){
          servicesOpt.push({label: `${el.code} - ${el.name}`, value: el.id})
        } else{
          compensationOpt.push({label: `${el.code} - ${el.name}`, value: el.id})
        }
      })
      setServicesOptions(servicesOpt)
      setCompensationCodeOptions(compensationOpt)
    })
  }

  useEffect(() => {
    if(bill){
      if((bill.serviceAmount && bill.serviceAmount !== "" && servicesOptions.length === 0) || 
      (bill.compensationAmount && bill.compensationAmount !== "" && compensationCodeOptions.length === 0)){
        goToGetServices()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bill])

  const filterComments = (state) => {
    setCommentsOptions(allComments.filter( comment => comment.state.id === state?.id))
  }

  useEffect(() => {
    if(bill && allComments) filterComments(bill.state)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bill, allComments])

  const servicesJoin = () => {
    let services = []
    if(bill.listServices){
      bill.listServices.forEach( ser => {
        services.push(ser.value)
      })
    }
    if(bill.listCompensations){
      bill.listCompensations.forEach( ser => {
        services.push(ser.value)
      })
    }
    return services
  }

  const submitBill = () => {
    
    const billForSave = {
      ...bill,
      checkAmount: calcCheckAmount(),
      stateId: bill.state.value,
      services: servicesJoin(),
      comments: bill.comments.length > 0 ? bill.comments.map(el => (el.value)) : bill.comments,
      typeOrderId: bill.typeOrder.value
    }
    goToSaveBill(billForSave)
  }

  return (
    <>
      {bill && 
        <form id="billForm" className="row" onSubmit={handleSubmit(submitBill)} autoComplete="off">
          {/* ORDER TYPE */}
          <Controller
            control={control}
            name="orderType"
            rules={{required: bill.typeOrder === null }}
            render={({ field:{onChange, ref}, fieldState: {error}}) => (
              <Select
                formField={{
                  className:"col-lg-6 col-md-6 col-sm-12 selectOrderType", 
                  shouldValidate: error !== undefined, 
                  validation: {
                    id:"orderType",
                    msg:t('validation.required'), 
                    type:"error"
                  }
                }}
                label={t('bill.orderType')}
                onChange={val => {
                  setBill({...bill, typeOrder: val})
                  onChange(val)
                }}
                value={bill.typeOrder}
                isSearchable={false}
                id="orderType"
                name="orderType"
                options={orderTypeOptions}
                readOnly={readOnly}
                inputRef={ref}
              />
            )} 
          />
          {/* DATE SAMS CLOSE */}
          <Controller
            control={control}
            name="date"
            rules={{required: bill.endDate === ""}}
            render={({ field:{onChange, ref}, fieldState: {error}}) => (
            <DatePicker
              formField={{
                className:"col-lg-6 col-md-6 col-sm-12",
                shouldValidate: error !== undefined,
                validation: {
                  id:"date",
                  msg:t('validation.required'), 
                  type:"error"
                }
              }}
              id="date"
              name="date"
              label={t('bill.closeSams')}
              value={bill.endDate}
              inputRef={ref}
              onChange={(e) => {
                setBill({...bill, endDate: e.target.value})
                onChange(e.target.value)
              }}
              readOnly={readOnly}
            />
          )} 
          />

          <div className="col-12">
            <div className="row">
              {/* SALE COMMODITY */}
              <Input
                formField={{
                  className: "col-lg-4 col-md-6",
                  qa: "saleCommodity"
                }}
                input={{
                  id: "saleCommodity",
                  type: "text",
                  label: t("bill.saleCommodity"),
                  value: bill.salesAmount ? bill.salesAmount : "",
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setBill({...bill, salesAmount: e.target.value}),
                  suffixLabel:"€",
                  readOnly: readOnly
                }}
              />

              {/* RETURN COMMODITY */}
              <Input
                formField={{
                  className: "col-lg-4 col-md-6",
                  qa: "returnCommodity"
                }}
                input={{
                  id: "returnCommodity",
                  type: "text",
                  label: t("bill.returnCommodity"),
                  value: bill.returnAmount ? bill.returnAmount : "",
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setBill({...bill, returnAmount: e.target.value}),
                  suffixLabel:"€",
                  readOnly: readOnly
                }}
              />
            </div>
          </div>
          
          <div className="col-12">
            <div className="row" qa="amountServices">
              {/* AMOUNT SERVICES */}
              <Input
                formField={{
                  className: "col-lg-4 col-md-4 col-sm-12"
                }}
                input={{
                  id: "amountServices",
                  type: "text",
                  label: t('bill.amountServices'),
                  value: bill.serviceAmount ? bill.serviceAmount : "",
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setBill({...bill, serviceAmount: e.target.value}),
                  suffixLabel:"€",
                  readOnly: readOnly
                }}
              />

              {/* SERVICES */}
              <Controller
                control={control}
                name="services"
                rules={{required: bill.serviceAmount !== "" && bill.serviceAmount !== null && bill.listServices === null}}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-lg-8 col-md-8 col-sm-12",
                    shouldValidate: error !== undefined, 
                    validation: {
                      id:"services",
                      msg:t('validation.required'), 
                      type:"error"
                    }
                  }}
                  label={t('bill.services')}
                  inputRef={ref}
                  onChange={val => {
                    setBill({...bill, listServices: val})
                    onChange(val)
                  }}
                  value={bill.listServices}
                  isSearchable={true}
                  isMulti={true}
                  isClearable={true}
                  id="services"
                  name="services"
                  options={servicesOptions}
                  readOnly={readOnly}
                  isDisabled={!readOnly ? bill.serviceAmount === "" || bill.serviceAmount === null : false}
                />
                )}
              />
            </div>
          </div>
          
          <div className="col-12">
            <div className="row" qa="amountCompensation">
              {/* AMOUNT COMPENSATION */}
              <Input
                formField={{
                  className: "col-lg-4 col-md-4 col-sm-12"
                }}
                input={{
                  id: "amountCompensation",
                  type: "text",
                  label: t("bill.amountCompensation"),
                  value: bill.compensationAmount ? bill.compensationAmount : "",
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setBill({...bill, compensationAmount: e.target.value}),
                  suffixLabel:"€",
                  readOnly: readOnly
                }}
              />

              {/* COMPENSATION CODE */}
              <Controller
                control={control}
                name="listCompensations"
                rules={{required: bill.compensationAmount !== "" && bill.compensationAmount !== null && bill.listCompensations === null}}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-lg-8 col-md-8 col-sm-12",
                      shouldValidate: error !== undefined, 
                      validation: {
                        id:"listCompensations",
                        msg:t('validation.required'), 
                        type:"error"
                      }
                    }}
                    label={t('bill.compensationCode')}
                    inputRef={ref}
                    onChange={val => {
                      setBill({...bill, listCompensations: val})
                      onChange(val)
                    }}
                    value={bill.listCompensations}
                    isSearchable={true}
                    isMulti={true}
                    isClearable={true}
                    id="listCompensations"
                    name="listCompensations"
                    options={compensationCodeOptions}
                    readOnly={readOnly}
                    isDisabled={!readOnly ? bill.compensationAmount === "" || bill.compensationAmount === null : false}
                  />
                  )}
              />
              
            </div>
          </div>
          

          {/* CORRECTIVE BILL */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={style.pairInputs + " row"}>
              <Input
                formField={{
                  className: "col-8",
                  qa: "corrective"
                }}
                input={{
                  id: "corrective",
                  type: "text",
                  label: t("bill.numCorrectiveBill"),
                  value: bill.rectifyingInvoiceNumber ? bill.rectifyingInvoiceNumber : "",
                  onChange: e => setBill({...bill, rectifyingInvoiceNumber: e.target.value}),
                  readOnly: readOnly
                }}
              />
              
              <Controller
                control={control}
                name="rectifyingInvoiceAmount"
                rules={{required: bill.rectifyingInvoiceNumber !== "" && bill.rectifyingInvoiceNumber !== null }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                  <Input
                    formField={{
                      className: "col-4",
                      qa: "correctiveAmount",
                      shouldValidate: error !== undefined,
                      valid:"error", 
                      validation: {
                        id:"rectify",
                        msg:t('validation.required'), 
                        type:"error"
                      }
                    }}
                    input={{
                      id: "correctiveAmount",
                      type: "text",
                      label: t("global.amount"),
                      value: bill.rectifyingInvoiceAmount ? bill.rectifyingInvoiceAmount : "",
                      inputref:{ref},
                      onKeyDown: (e) => (CheckNum(e)),
                      onChange: e => {
                        setBill({...bill, rectifyingInvoiceAmount: e.target.value})
                        onChange(e.target.value)
                      },
                      suffixLabel:"€",
                      readOnly: readOnly,
                      disabled: (bill.rectifyingInvoiceNumber === "" || bill.rectifyingInvoiceNumber === null) && !readOnly
                    }}
                  />
                  )}
                />
                <SSRIcon className={style.icon} paths={arrowOnPath} />
            </div>
          </div>

          {/* MANUAL BILL */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className={style.pairInputs + " row"}>
              <Input
                formField={{
                  className: "col-8",
                  qa: "manual"
                }}
                input={{
                  id: "manual",
                  type: "text",
                  label: t("bill.numManualBill"),
                  value: bill.manualInvoiceNumber ? bill.manualInvoiceNumber : "",
                  onChange: e => setBill({...bill, manualInvoiceNumber: e.target.value}),
                  readOnly: readOnly
                }}
              />
              
              <Controller
                control={control}
                name="manualInvoiceAmount"
                rules={{required: bill.manualInvoiceNumber !== "" && bill.manualInvoiceNumber !== null }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                  <Input
                    formField={{
                      className: "col-4",
                      qa: "manualAmount",
                      shouldValidate: error !== undefined,
                      valid:"error", 
                      validation: {
                        id:"manualInvoiceAmount",
                        msg:t('validation.required'), 
                        type:"error"
                      }
                    }}
                    input={{
                      id: "manualAmount",
                      type: "text",
                      label: t("global.amount"),
                      value: bill.manualInvoiceAmount ? bill.manualInvoiceAmount : "",
                      inputref:{ref},
                      onKeyDown: (e) => (CheckNum(e)),
                      onChange: e => {
                        setBill({...bill, manualInvoiceAmount: e.target.value})
                        onChange(e.target.value)
                      },
                      suffixLabel:"€",
                      readOnly: readOnly,
                      disabled: (bill.manualInvoiceNumber === "" || bill.manualInvoiceNumber === null) && !readOnly
                    }}
                  />
                  )}
                />
                <SSRIcon className={style.icon} paths={arrowOnPath} />
            </div>
          </div>
          
          {/* CHECK AMOUNT */}
          <Input
            formField={{
              className: "col-lg-3 col-md-6 col-sm-6",
              qa: "checkAmount"
            }}
            input={{
              id: "checkAmount",
              type: "text",
              label: t("bill.checkAmount"),
              value: calcCheckAmount(),
              suffixLabel:"€",
              readOnly: true
            }}
          />
          {/* CHECK BILL */}
          <Input
            formField={{
              className: "col-lg-3 col-md-6 col-sm-6",
              qa: "checkBill"
            }}
            input={{
              id: "checkBill",
              type: "text",
              label: t("bill.checkBill"),
              value: calcCheckBill(),
              suffixLabel:"€",
              readOnly: true
            }}
          />
          {/* FORCE CHECK BILL */}
          <Input
            formField={{
              className: `col-lg-3 col-md-6 col-sm-6 ${style.force}`,
              qa: "force",
            }}
            input={{
              id: "force",
              type: "text",
              label: t("bill.forceCheckBill"),
              className: bill.checkForcedInvoice === "0" ? style.isForce : '',
              value: bill.checkForcedInvoice !== null ? bill.checkForcedInvoice : calcCheckBill(),
              onKeyDown: (e) => (CheckNum(e)),
              onChange: e => setBill({...bill, checkForcedInvoice: e.target.value === "" ? "0" : e.target.value}),
              suffixLabel:"€",
              ...(!readOnly ? {ssrIcon: returnBtn} : {}),
              iconPosition: "trailing",
              iconOnClick: () => setBill({...bill, checkForcedInvoice: calcCheckBill()}),
              readOnly: readOnly
            }}
          />
          
          {/* DIFERENCE */}
          <Input
            formField={{
              className: "col-lg-3 col-md-6 col-sm-6",
              qa: "difference"
            }}
            input={{
              id: "difference",
              type: "text",
              className: style.difference,
              label: t("bill.difference"),
              value: bill.cashDifference ? bill.cashDifference : "",
              onKeyDown: (e) => (CheckNum(e)),
              onChange: e => setBill({...bill, cashDifference: e.target.value}),
              suffixLabel:"€",
              readOnly: readOnly
            }}
          />
          
          {/* STATE */}
          <Controller
            control={control}
            name="state"
            rules={{required: bill.state === null || bill.state?.id === STATE.RESERVED || bill.state?.id === STATE.ASSIGNED}}
            render={({ field:{onChange, ref}, fieldState: {error}}) => (
              <Select
                formField={{
                  className:"col-lg-4 col-md-6 col-sm-12 stateSelector",
                  shouldValidate: error !== undefined, 
                  validation: {
                    id:"state",
                    msg:t('validation.required'), 
                    type:"error"
                  }
                }}
                label={t('global.state')}
                inputRef={ref}
                onChange={val => {
                  setBill({...bill, state: val, comments: []})
                  onChange(val)
                  filterComments(val)

                }}
                value={bill.state?.value !== STATE.AVAILABLE ? bill.state : null}
                isSearchable={true}
                id="state"
                name="state"
                options={stateOptions}
                readOnly={readOnly}
              />
            )}
          />

          {/* COMMENTS */}
          <Controller
            control={control}
            name="comments"
            rules={{required: 
              (bill.comments.length === 0 && bill.cashDifference > 10 && bill.repeatedSams.length === 0) || 
              (bill.state?.requiredComment && bill.comments.length === 0 && bill.repeatedSams.length === 0)
            }}
            render={({ field:{onChange, ref}, fieldState: {error}}) => (
              <Select
                formField={{
                  className:"col-lg-8 col-md-6 col-lg-12 commentsSelector",
                  shouldValidate: error !== undefined, 
                  validation: {
                    id:"comments",
                    msg:t('validation.required'), 
                    type:"error"
                  }
                }}
                label={t('menu.comments')}
                inputRef={ref}
                onChange={val => {
                  setBill({...bill, comments: val})
                  onChange(val)
                }}
                value={bill.comments}
                isSearchable={true}
                isMulti={true}
                isClearable={true}
                id="comments"
                name="comments"
                options={commentsOptions}
                readOnly={readOnly}
                isDisabled={commentsOptions.length === 0 && !readOnly}
              />
            )}
          />
          {/* OBSERVATIONS */}
          {bill.state?.id !== STATE.CLOSE &&
            <Textarea
            formField={{
              className: "col-12",
              qa:"observations"
            }}
            textArea={{
              label: t("bill.observations"),
              value: bill.observation ? bill.observation : "",
              onChange: e => setBill({...bill, observation: e.target.value}),
              readOnly: readOnly
            }}
          />
          }
        </form>
      }
    </>
  )
}
