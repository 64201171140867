import { useTranslation } from "react-i18next";
import Button from "../ingka/Button";
import { useEffect, useState } from "react";
import Modal, { Sheets, ModalBody, ModalHeader } from '@ingka/modal';
import style from './createEditServices.module.scss';
import { Controller, useForm } from "react-hook-form";
import DatePicker from "./DatePicker";
import { exportBills } from "../../services/exportServices";
import Select from "./Select";
import { useSelector } from "react-redux";
import ArrayToOption from "../../utils/arrayToOption";

export default function ExportPeriod() {
  const [t] = useTranslation("global")
  const { handleSubmit, control} = useForm();
  const[modalOpen, setModalOpen] = useState(false)
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [state, setState] = useState(null)
  const [stateOptions, setStateOptions] = useState(null)
  const [disabledClick, setDisabledClick] = useState(false)

  const states = useSelector((state) => state.global.states)

  useEffect(() => {
    if(states){
      setStateOptions(ArrayToOption(states, "id", "name"))
    }
  },[states])

  const goToExport = () => {
    setModalOpen(false)
    setDisabledClick(true)
    exportBills({
      startOfEndDate: fromDate  === "" ? null : fromDate, 
      endOfEndDate: toDate === "" ? null : toDate,
      state: state?.value,
      requestSendMsg: t('breakdown.exportBills'),
      downloadSuccess: t('breakdown.downloadSuccess')
    }).then( () => setDisabledClick(false))
  } 

  return (
    <>
      <Button
        type="emphasised"
        text={t("breakdown.exportPeriod")}
        className="ml-1"
        onClick={() => setModalOpen(!modalOpen)}
        disabled={disabledClick}
        qa="buttonExportPeriod"
      />

      <Modal
        visible={modalOpen}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalOpen(!modalOpen)}
        qa="exportPeriodModal"
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className={style.modal_header} title={t("breakdown.exportPeriod")}/>}
        >
          <ModalBody >
            <form id="exportForm" className="row" onSubmit={handleSubmit(goToExport)}>
              <Controller
                control={control}
                name="fromDate"
                rules={{required: false }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                <DatePicker
                  qa="fromDate"
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation: {
                      id:"fromDate",
                      msg:t('validation.required'), 
                      type:"error"
                    }
                  }}
                  label={t('global.from')}
                  value={fromDate}
                  inputRef={ref}
                  onChange={(e) => {
                    setFromDate(e.target.value)
                    onChange(e.target.value)
                  }}
                />
              )} 
              />
              <Controller
                control={control}
                name="toDate"
                rules={{required: false }}
                render={({ field:{onChange, ref}, fieldState: {error}}) => (
                <DatePicker
                  qa="toDate"
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation: {
                      id:"toDate",
                      msg:t('validation.required'), 
                      type:"error"
                    }
                  }}
                  label={t('global.to')}
                  value={toDate}
                  inputRef={ref}
                  onChange={(e) => {
                    setToDate(e.target.value)
                    onChange(e.target.value)
                  }}
                />
              )} 
              />

              <Select
                formField={{className:"col-12"}}
                label={t('global.state')}
                onChange={val => setState(val)}
                value={state}
                qa="state"
                name="state"
                options={stateOptions}
              />
            </form>
          </ModalBody>
          <div className={style.submit}>
            <Button
              type="emphasised"
              text={t("global.export")}
              form="exportForm"
              htmlType="submit"
              disabled={toDate === "" && fromDate === "" && state === null}
            />
          </div>
        </Sheets>
      </Modal>
    </>
  )
}
