import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';

export const uploadFile = (file) => {
  const fetchSettings = {
    method: "POST",
    url: API_URLS.UPLOAD_EXCEL,
    data: file,
    body: true,
    contentType: 'multipart/form-data',
  }
  const user = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
      store.dispatch(createMsgToast({body: response, variant:"positive"}))
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return user;
}

export const getImportsLogs = ({params}) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.LOGS,
    data: params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch;
}