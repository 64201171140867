import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { Sheets, ModalBody, ModalHeader } from '@ingka/modal';
import style from './createEditServices.module.scss';
import Button from '../ingka/Button';
import upload from "@ingka/ssr-icon/paths/arrow-up-from-base";

const UploadFile = ({ visible, title, onClose, onSaveFile }) => {

  const [t] = useTranslation('global');
  const [fileName, setFileName] = useState(null)
  const [file, setFile] = useState(null)
  const [disabledSave, setDisabledSave] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (file) {
      setDisabledSave(false);
      return;
    }
    setDisabledSave(true);
  }, [file])

  const openFile = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setFile(file);
      setFileName(file.name);
    }
    e.target = window._protected_reference = undefined;
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  const save = () => {
    onSaveFile(file);
    setFile(null);
    onClose();
  }

  return (
    <Modal
        visible={visible}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={onClose}
        qa="modalImport"
      >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={
            <ModalHeader
              className={style.modal_header}
              title={title}
            />}
        >
          <ModalBody>
            <Button
              type="secondary"
              text={fileName === null ? t("import.uploadPeriodFile") : fileName}
              ssrIcon={upload}
              onClick={handleClick}
              className="col-12"
              qa="buttonSelectFile"
            />
            <input 
              type='file' 
              accept='.xlsx, .xls, .csv' 
              style={{opacity: 0, height: 0}} 
              ref={inputRef}
              onChange={(e) => openFile(e)}
            />
            <Button
              type="emphasised"
              text={t("import.uploadFile")}
              onClick={save}
              className="mt-3 col-12"
              disabled={disabledSave}
              qa="buttonUploadFile"
            />
          </ModalBody>
        </Sheets>
      </Modal>
  )
}

export default UploadFile;