import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/modal/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/carousel/style.scss";
import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import { OverflowCarousel } from "@ingka/carousel";
import Button from "../ingka/Button";
import { useEffect, useState } from "react";
import filterIcon from '@ingka/ssr-icon/paths/filters'
import { useTranslation } from "react-i18next";
import style from "./filter.module.scss"
import { useSelector } from "react-redux";
import Select from "./Select";
import DatePicker from "./DatePicker";
import Input from "../ingka/Input";
import { BILLER } from "../../utils/roles";
import { getUsers } from "../../services/usersService";
import { LoadingBall } from "@ingka/loading";
import { getPaymentTypes } from '../../services/paymentTypeService';
import CheckNum from "../../utils/checkNum";
import ArrayToOption from "../../utils/arrayToOption";
import Text from "../ingka/Text";

export default function Filter({rol, pushFilters}) {
  const[modalOpen, setModalOpen] = useState(false)
  const [t] = useTranslation("global")
  const userSession = useSelector((state) => state.global.user)
  const states = useSelector((state) => state.global.states)
  const rowsOptions = [
    {label: "12", value: 12},
    {label: "25", value: 25},
    {label: "50", value: 50},
    {label: "100", value: 100},
    {label: "500", value: 500},
    {label: "1000", value: 1000},
  ]
  const initialFilters = {
    users: null,
    paymentTypeId: null,
    homeValueMin: "",
    homeValueMax: "",
    startDocDate: "",
    endDocDate: "",
    sams: "",
    statesIds: [
      {label: 'ASIGNADO', value: 4},
      {label: 'RESERVADO', value: 5},
      {label: 'DISPONIBLE', value: 3}
    ],
    startOfEndDate: "",
    endOfEndDate: "",
    size: rowsOptions[0]
  }

  const [filtersLabel, setFiltersLabel] = useState([
    {
      label:"ASIGNADO, RESERVADO, DISPONIBLE",
      value:"4,5,3",
      key:"statesIds",
      title:"Estados"
    } 
  ])
  const [disabledFilter, setDisabledFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const [userOptions, setUserOptions] = useState(null)
  const [payModeOptions, setPayModeOptions] = useState(null)
  const [stateOptions, setStateOptions] = useState(null)
  
  useEffect(() => {
    if(userSession && rol && modalOpen){
      if(rol !== BILLER){
        setLoading(true)
        getUsers().then(users => {
          setUserOptions(ArrayToOption(users, "nickName", "name"));
        }).finally(() => setLoading(false))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rol, userSession, modalOpen])

  useEffect(() => {
    if(states){
      setStateOptions(ArrayToOption(states, "id", "name"))
    }
  },[states])

  useEffect(() => {
    setLoading(true)
    getPaymentTypes().then( payments => {
      setPayModeOptions(ArrayToOption(payments, "id", "name"))
    }).finally(() => setLoading(false))
  },[])

  useEffect(() => {
    //filter button controller
    if(rol === BILLER){
      if(( filters.paymentTypeId || filters.homeValueMin || filters.homeValueMax || filters.startDocDate || filters.endDocDate || filters.sams || filters.statesIds || filters.startOfEndDate || filters.endOfEndDate || filters.size.value !== 12)){
        setDisabledFilter(false)
      } else {
        setDisabledFilter(true)
      }
    }
  },[filters, rol])

  const applyFilter = () => {
    //console.log(filters)
    let usedFilters = []
    const valueParse = (value) => {
      let val = {label:"", value: ""}
      if(value.label){
        val = {label : value.label, value: value.value}
      }else if(value instanceof Array){
        value.forEach( (el, index) => {
          val.label += index !== 0 ? ", " + el.label: el.label
          val.value += index !== 0 ? "," + el.value: el.value
        })
      }else{
        val = {label: value, value: value}
      }
      return val
    }

    Object.entries(filters).forEach(([key, value]) => {
      if(value && value.length !== 0){
        usedFilters.push({
          ...valueParse(value),
          key: key, 
          title: t(`bills.${key}`)
        })
      }
    });

    setFiltersLabel(usedFilters)
    setModalOpen(!modalOpen)
    const page = 0
    let filtersForQuery = {}
    usedFilters.forEach( filter => {
      filtersForQuery[filter.key] = filter.value
    })
    pushFilters(filtersForQuery, page)
  }
  
  const resetFilters = () => {
    setFilters({...initialFilters})
    setFiltersLabel(null)
    pushFilters(null, 0)
  }

  return (
    <section>
      <div className={style.containerFilters}>    
        <div className={style.filterButtons} qa="filterCarousel">
          <OverflowCarousel
          controlBtnType="primary">
            <Button 
              onClick={() => setModalOpen(!modalOpen)}
              type="tertiary"
              iconOnly
              ssrIcon={filterIcon}
              small
              qa="openFilterBtn"
            />
            {filtersLabel === null &&
              <Button 
                type="tertiary"
                text={t('components.filter.noFilters')}
                small
                className={style.filterButton}
                onClick={()=>setModalOpen(!modalOpen)}
                qa="noFilterBtn"
              />
            }
            {filtersLabel &&
              filtersLabel.map((filter, index) => (
                <Button
                key={index}
                type="tertiary"
                text={filter.title + ": " + filter.label}
                small
                className={style.filterButton}
                onClick={()=>setModalOpen(!modalOpen)}
                />
              ))
              
            }
          </OverflowCarousel>
          <hr/>
        </div>
      </div>
      <Modal
        visible={modalOpen}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalOpen(!modalOpen)}
        qa="filterModal"
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className={style.modal_header} title={t("components.filter.title")}/>}
        >
          <>
            {loading &&
              <div className={style.loading}>
                <LoadingBall />
              </div>
            } 
          </>
          <ModalBody className={style.modal_body}>
            <form id="filterForm" className="row" autoComplete="off">
              <div className={style.reset}>
                <Button
                  type="tertiary"
                  text={t('components.filter.clean')}
                  small
                  className={style.filterButton}
                  onClick={() => resetFilters()} 
                />
              </div>

              {/* USER SELECTOR */}
              {rol !== BILLER &&
                <Select
                formField={{className:"col-12"}}
                label={t('global.user')}
                onChange={val => setFilters({...filters, users: val})}
                value={filters.users}
                isClearable={true}
                isMulti={true}
                isSearchable={true}
                qa="user"
                name="user"
                options={userOptions}
                defaultValue={null}
              />
              }

              {/* PAY MODE SELECTOR */}
              <Select
                formField={{className:"col-12"}}
                label={t('bills.paymentTypeId')}
                onChange={val => setFilters({...filters, paymentTypeId: val})}
                value={filters.paymentTypeId}
                isSearchable={false}
                qa="payMode"
                name="payMode"
                options={payModeOptions}
              />

              {/* AMOUNT */}
              <Input
                formField={{
                  className: "col-6",
                  qa:"amountFrom"
                }}
                input={{
                  id:"amountFrom",
                  type:"text",
                  label: t("bills.homeValueMin"),
                  value: filters.homeValueMin,
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setFilters({...filters, homeValueMin: e.target.value}),
                  suffixLabel:"€",
                }}
              />
              <Input
                formField={{
                  className: "col-6",
                  qa:"amountTo"
                }}
                input={{
                  id:"amountTo",
                  type:"text",
                  label: t("bills.homeValueMax"),
                  value: filters.homeValueMax,
                  onKeyDown: (e) => (CheckNum(e)),
                  onChange: e => setFilters({...filters, homeValueMax: e.target.value}),
                  suffixLabel:"€",
                }}
              />

              {/* DOCUMENT DATE */}
              <DatePicker
                formField={{
                  className:"col-6",
                }}
                label={t('bills.startDocDate')}
                value={filters.startDocDate}
                onChange={(e) => setFilters({...filters, startDocDate: e.target.value})}
                qa="documentFrom"
              />
              <DatePicker
                formField={{
                  className:"col-6",
                }}
                label={t('bills.endDocDate')}
                value={filters.endDocDate}
                onChange={(e) => setFilters({...filters, endDocDate: e.target.value})}
                qa="documentTo"
              />

              {/* SAMS */}
              <Input
                formField={{
                  className: "col-12",
                  qa:"sams"
                }}
                input={{
                  id:"sams",
                  type:"text",
                  label: "SAMS",
                  value: filters.sams,
                  onChange: e => setFilters({...filters, sams: e.target.value}),
                }}
              />
              
              {/* STATE SELECTOR */}
              <Select
                formField={{className:"col-12"}}
                label={t('bills.statesIds')}
                onChange={val => setFilters({...filters, statesIds: val})}
                value={filters.statesIds}
                isClearable={true}
                isMulti={true}
                isSearchable={false}
                qa="state"
                name="state"
                options={stateOptions}
              />

              {/* REVIEW DATE */}
              <DatePicker
                formField={{
                  className:"col-6",
                }}
                label={t('bills.startOfEndDate')}
                value={filters.startOfEndDate}
                onChange={(e) => setFilters({...filters, startOfEndDate: e.target.value})}
                qa="reviewFrom"
              />
              <DatePicker
                formField={{
                  className:"col-6",
                }}
                label={t('bills.endOfEndDate')}
                value={filters.endOfEndDate}
                onChange={(e) => setFilters({...filters, endOfEndDate: e.target.value})}
                qa="reviewTo"
              />

              {/* ROWS SELECTOR */}
              <Select
                formField={{
                  className:"col-12"
                }}
                label={t('bills.rowsPerPage')}
                onChange={val => setFilters({...filters, size: val})}
                value={filters.size}
                isSearchable={false}
                qa="size"
                name="size"
                options={rowsOptions}
              />
            </form>
          </ModalBody>
          <div className={style.submit} qa="submit">
            {filters.size.value > 100 &&
              <Text tagName="p" bodySize="m" className="text-red text-center m0">{t('components.filter.slowLoad')}</Text>
            }
            <Button
              type="emphasised"
              text={t("global.filter")}
              onClick={applyFilter}
              disabled={disabledFilter}
            />
          </div>
        </Sheets>
      </Modal>
    </section>
  )
}