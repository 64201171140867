import style from "./list.module.scss"
export default function List({header, items}) {
  return (
    <ul className={style.list}>
      <li qa="listHeader"><span><b>{header.title}</b></span><span><b>{header.amount}</b></span></li>
      <hr></hr>
      {
        items.map( (item, index) => (
          <li key={index} qa="listItem"><span>{item.title}</span><span>{item.amount}</span></li>
        ))
      }
    </ul>
  )
}