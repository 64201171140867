import { API_URLS } from '../configuration/api.constants';
import { apiFetch } from '../configuration/apiFetch';
import { store } from '../store/store';
import { createMsgToast } from '../store/globalSlice';
import CryptoJS from "crypto-js"
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_CODE)

const decode = (data) => {
  const dataBytes = CryptoJS.enc.Base64.parse(data);
  const dataDecode = CryptoJS.AES.decrypt(
    { ciphertext: dataBytes },
    key,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  ).toString(CryptoJS.enc.Utf8)
  return dataDecode
}

export const exportBills = ({
  startOfEndDate = null, 
  endOfEndDate = null, 
  invoicesIds= null, 
  state=null,
  requestSendMsg, 
  downloadSuccess}) => {

  const fetchSettings = {
    method: "POST",
    url: API_URLS.EXPORT,
    data: {
      startOfEndDate: startOfEndDate, 
      endOfEndDate: endOfEndDate, 
      invoicesIds: invoicesIds,
      state: state
    }
  }
  store.dispatch(createMsgToast({body: requestSendMsg, variant:"positive"}))

  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response) => {
      if(response.response) throw response
      let anchor = document.createElement("a")
      anchor.href = process.env.REACT_APP_API + decode(response)
      anchor.click()
      store.dispatch(createMsgToast({body: downloadSuccess, variant:"positive"}))
      resolve()
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}