import { API_URLS } from '../configuration/api.constants';
import { apiFetch } from '../configuration/apiFetch';
import { closeBill, createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';

export const getBills = ({isAdmin, params}) => {
  const fetchSettings = {
    method: "GET",
    url: isAdmin ? API_URLS.INVOICES : API_URLS.INVOICES_BY_USER,
    data: params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getSummary = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.SUMMARY,
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      const summary = response.map( el => ({title: el.name, amount: el.total}))
      resolve(summary)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getBillsFiltered = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.INVOICES_DYNAMIC,
    data: params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const billsActions = ({billsId, userId, action}) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.ACTIONS(action),
    data: {
      invoiceIds: billsId,
      user: userId
    }
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  }) 
  return dataFetch
}

export const getBill = ({billId}) => {
  const fetchSettings = {
    method: "GET",
    url: billId ? API_URLS.INVOICE_BY_BILL(billId) : API_URLS.INVOICE_BY_USER,
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      response.listServices = response.services.length > 0 ? response.services.filter(ser => ser.typeService.id === 1) : null
      response.listCompensations = response.services.length > 0 ? response.services.filter(ser => ser.typeService.id === 2) : null
      resolve(response)
    }).catch((response)=>{
      store.dispatch(closeBill(true))
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getTypeOrders = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.TYPE_ORDERS,
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      const options = response.map( el => ({label: el.name, value: el.id}))
      resolve(options)
    }).catch((response)=>{
      store.dispatch(closeBill())
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const saveBill = ({billId, data}) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.SAVE_INVOICE(billId),
    data: data,
    body: true
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(closeBill())
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getBreakdown = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.TOTAL_SUMMARY,
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=> {
      if(response?.response) throw response;
      resolve(response);
    }).catch((response)=> {
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}));
    })
  })
  return dataFetch;
}

