import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';

export const getPaymentMethods = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.PAYMENT_METHODS,
  }
  const paymentMethods = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return paymentMethods;
}

export const getPaymentTypes = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.PAYMENT_TYPES,
  }
  const paymentTypes = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return paymentTypes;
}