import InkaText from "@ingka/text";
import "@ingka/text/style.scss";

export default function Text({className, prefix, bodySize, headingSize, tagName, children, qa}) {
  return (
    <InkaText 
      className={className}
      prefix={prefix}
      bodySize={bodySize}
      headingSize={headingSize}
      tagName={tagName}
      children={children}
      qa={qa}
    />
  )
}