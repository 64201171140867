import { API_URLS } from '../configuration/api.constants';
import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


export const getStates = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.STATES,
  }
  const states = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}));
    });
  })
  return states
}

export const updateState = (modifiedStatus, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.STATE(modifiedStatus.id),
    data: modifiedStatus,
    body: true
  }

  const states = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}));
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}));
    });
  })
  return states
}