import { useState } from 'react';
import { useTranslation } from "react-i18next";
import MainMenu from './MainMenu';

import Button from '../ingka/Button';
import Text from '../ingka/Text';
import style from './header.module.scss'
import documentAdd from '@ingka/ssr-icon/paths/document-add'
import chevronDown from '@ingka/ssr-icon/paths/menu'
import cross from '@ingka/ssr-icon/paths/cross'
import logoutIcon from '@ingka/ssr-icon/paths/arrow-right-from-base'

import { useDispatch } from "react-redux";
import { doLogout, openBill } from '../../store/globalSlice';
import { NavLink} from 'react-router-dom';
//import SelectIngka from '../ingka/SelectIngka';


export default function Header() {
  const [clicked, setClicked] = useState(false)
  const [t] = useTranslation("global")
  //const lang = useSelector((state) => state.global.language)

  //const languages = [{ name: t("language.labelEs"), value: "es" }, { name: t("language.labelEn"), value: "en" }]
  const dispatch = useDispatch();

  /* const changeLanguage = (e) => {
    dispatch(changeLang(e.target.value))
    i18n.changeLanguage(e.target.value)
    window.location.reload(false);
  } */

  const toggleMenu = () => {
    setClicked(!clicked)
  }

  const logout = (e) => {
    e.preventDefault()
    dispatch(doLogout(true))
  }

  const openCreateBill = () => {
    dispatch(openBill())
  }

  return (
    <header className={style.header} >
      <div className='container-header'>
        <div className={style.left}>
          <Button
            type="emphasised"
            iconOnly
            small
            ssrIcon={chevronDown}
            className={style.ham_btn}
            onClick={() => toggleMenu()}
            qa='buttonOpenMenu'
          />
          <NavLink to="/" className={style.title}>
            <Text
              tagName="h1"
              headingSize="m"
            >
              <span>{t('header.title')}</span>
            </Text>
          </NavLink>
        </div>

        <div className={style.right}>
          <Button
            type="emphasised"
            iconOnly
            small
            ssrIcon={documentAdd}
            className={style.button}
            onClick={() => openCreateBill()}
            title={t('menu.createBill')}
            qa="buttonBillByUser"
          />
        </div>

        {/* Side menu */}
        <aside className={`${style.side_menu} ${clicked ? style.open : ''}`}>
          <div className={style.side_menu_header}>
            <Text
              tagName="h3"
              headingSize="m"
              className={style.title}>
              <span>{t('header.title')}</span>
            </Text>
            <Button
              type="tertiary"
              iconOnly
              small
              ssrIcon={cross}
              onClick={() => toggleMenu()}
              qa="buttonCloseMenu"
            />
          </div>
          <div className={style.side_menu_body}>
            <MainMenu changeView={() => toggleMenu()}/>
          </div>
          <div className={style.side_menu_footer}>
            {/* <SelectIngka
              select={{
                onChange: changeLanguage,
                id: "langSelector",
                value: lang
              }}
              options={languages}
            /> */}
            <Button
              type="secondary"
              className={style.logout}
              text={t("header.logoutButton")}
              small
              iconPosition="trailing"
              ssrIcon={logoutIcon}
              onClick={logout}
              qa='header-btn-logout'
            />
          </div>
        </aside>
        <div className={`${style.overlay} ${clicked ? style.open : ''}`}></div>
      </div>
    </header>
  )
}
