import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { ModalHeader, Prompt, ModalFooter } from "@ingka/modal";
import Button from '../ingka/Button';

export default function AlertConfirm({ visible, title, message, confirmButtonText, onClose, onConfirm }) {
  const [t] = useTranslation('global');

  return (
    <Modal visible={visible} escapable={false} handleCloseBtn={onClose}>
      <Prompt
        title={title}
        header={<ModalHeader ariaCloseTxt={t('global.close')} />}
        footer={
          <ModalFooter>
            <Button text={t('global.cancel')} type="secondary" onClick={onClose} qa='alert-cancel'/>
            <Button text={confirmButtonText} type="emphasised" onClick={onConfirm} qa='alert-confirm'/>
          </ModalFooter>
        }
      >
        <p>{message}</p>
      </Prompt>
    </Modal>
  );
};