import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import Filter from '../components/global/Filter';
import { useEffect, useState } from "react";
import Table from '../components/global/Table';
import Load from '../components/ingka/Load';
import { getBills, getBillsFiltered, getSummary } from "../services/billServices";
import Button from "../components/ingka/Button";
import iconGo from '@ingka/ssr-icon/paths/chevron-right-small'
import { useDispatch, useSelector } from "react-redux";
import BillActions from "../components/global/BillActions";
import { changeNeedReload, openBill, updateStates } from "../store/globalSlice";
import { getStates } from "../services/statesService";
import { BILLER } from "../utils/roles";
import { STATE } from "../utils/billStates";
import Summary from "../components/global/Summary";
import style from "../assets/scss/bills.module.scss"


export default function Bills() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const rol = useSelector((state) => state.global.rol.id)
  const states = useSelector((state) => state.global.states)
  const user = useSelector((state) => state.global.user)
  const needReload = useSelector((state) => state.global.needReload)

  const [loading, setLoading] = useState(false)
  const [columnsBills, setColumnsBills] = useState([
    {accessorKey: "paymentType.name", header: t('bills.payModeShort'),size:100},
    {accessorKey: "yearPeriod", header: t('global.period'),size:100},
    {accessorKey: "homeValue", header: t('global.amount'), size:100, Cell: ({cell, row}) => 
      <span>{formatCurrency(cell.getValue())}</span>
    },
    {accessorKey: "lineDescription", header: t('global.description')},
    {accessorKey: "docDate", header: t('global.date'), size:100, Cell:
    ({cell}) => 
      <span>{cell.getValue().slice(0,16).replace('T', ' / ')}</span>
    },
    {accessorKey: "externalReference3",header: "SAMS",size:100},
    {accessorKey: "state.name",header: t('global.state')},
  ])
  const DEFAULT_SIZE_ROWS = 12
  const [bills, setBills] = useState(null)
  const [billsSelected, setBillsSelected] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [summary, setSummary] = useState(null)
  const [filters, setFilters] = useState({
    statesIds: "4,5,3",
    size: 12,
    page: 0
  })
  

  const formatCurrency = (value) => {
    if(value){
      return Number.isInteger(value) ? value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) : value.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' });
    }else{
      alert(t('validation.homeValueNull'))
    }
  }

  const goToGetSummary = () => {
    setLoading(true)
    getSummary().then((response) =>{
      setSummary(response)
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    goToGetSummary()
  },[])

  const goToGetBills = (page=0) => {
    setLoading(true)
    setBills(null)
    getBills({isAdmin: rol !== BILLER, params:{page: page, size: DEFAULT_SIZE_ROWS}})
    .then((response) =>{
      setBills(response.content)
      setPageInfo({rowCount: response.totalElements, pageCount: response.totalPages, pageSize: DEFAULT_SIZE_ROWS, pageIndex: page})
      setFilters(null)
    })
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    if(user && rol){
      //goToGetBills()
      goToGetBillsFiltered(filters, 0)
      if(rol !== BILLER) setColumnsBills([{accessorKey: "user", header: t('global.user'),size:100}, ...columnsBills])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user, rol])

  useEffect(() => {
    if(!states){
      getStates()
      .then((states) => dispatch(updateStates(states)))
    }
  },[states, dispatch])

  const goToBill = (bill) => {
    dispatch(openBill(bill.id))
  }

  const goToGetBillsFiltered = (filtersQuery, page) => {
    if(filtersQuery){
      setFilters(filtersQuery)
      setLoading(true)
      setBills(null)
      getBillsFiltered({...filtersQuery, page: page})
      .then((response) =>{
        setBills(response.content)
        setPageInfo({rowCount: response.totalElements, pageCount: response.totalPages, pageSize: response.size, pageIndex: page})
      })
      .finally(() => setLoading(false))
    } else {
      goToGetBills()
    }
  }

  const reloadPage = () => {
    if(filters){
      goToGetBillsFiltered(filters, pageInfo.pageIndex)
    } else {
      goToGetBills(pageInfo.pageIndex)
    }
    goToGetSummary()
  }

  useEffect(() => {
    if(needReload){
      reloadPage()
      dispatch(changeNeedReload(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[needReload])
  
  return (
    <>
      <Text tagName="h2" headingSize="m">{t('menu.bills')}</Text>
      <Filter pushFilters={goToGetBillsFiltered} rol={rol}/>
      {loading &&
        <Load />
      }
      {!loading && summary && bills &&
        <section className="mt-1">
          {rol !== BILLER &&
            <article className={style.actions}>
              <BillActions 
                billsSelected={billsSelected}
                bills={bills}
                isMulti={true}
                reloadPage={reloadPage}
              />
              <Summary summary={summary}/>
            </article>
            }
          <article className="mt-1" qa="billsTable">
            <Table
              columns={columnsBills} 
              data={bills}
              short={{id:'docDate', desc: true}}
              enableRowSelection={rol !== BILLER}
              selectedRows={ sel => setBillsSelected(sel)}
              enableRowActions={true}
              enablePagination={true}
              pageChange={(pageIndex) => filters ? goToGetBillsFiltered(filters, pageIndex) : goToGetBills(pageIndex)}
              pageInfo={pageInfo}
              localization={{
                noRecordsToDisplay: t('bills.noBills')
              }}    
              renderRowActions= {({ row }) => {
                if(row.original.state.id !== STATE.PENDING &&
                  row.original.state.id !== STATE.ASSIGNED){
                  return(
                    <Button
                      qa="goToBill"
                      type="tertiary"
                      small
                      iconOnly
                      ssrIcon={iconGo}
                      onClick={() => goToBill(row.original)}
                    />
                  )
                }
              }}
            />
            <Text tagName="p" bodySizeSize="s" className="m0 text-right">{pageInfo.rowCount} {t('components.table.results').toLowerCase()}</Text>
          </article>
        </section>
      }
    </>
  )
}
