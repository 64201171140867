import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';
import { API_URLS } from '../configuration/api.constants';


export const getServices = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.SERVICES,
    data:{
      active: true
    }
  }
  const services = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return services;
}

export const deleteService = (serviceId, successMsg) => {
  const fetchSettings = {
    method: "DELETE",
    url: API_URLS.SERVICE_ACTION(serviceId),
  }
  const deleteResp = new Promise ((resolve) => {
    apiFetch(fetchSettings).then(()=>{
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
      resolve();
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return deleteResp;
}

export const servicesOptions = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.TYPES_SERVICES,
  }
  const options = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return options;
}

export const createService = (service, successMsg) => {
  const fetchSettings = {
    method: "POST",
    url: API_URLS.SERVICE,
    data: service,
    body: true
  }
  const newService = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}));
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return newService;
}

export const updateService = (serviceId, service, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.SERVICE_ACTION(serviceId),
    data: service,
    body: true
  }
  const updateService = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response;
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}));
      resolve(response);
    }).catch((error)=>{
      store.dispatch(createMsgToast({body: error.message, title:"Error", variant:"negative"}));
    })
  })
  return updateService;
}
