import { useTranslation } from "react-i18next";
import Text from '../ingka/Text';
import { Link, NavLink } from 'react-router-dom';
import style from "./mainMenu.module.scss"

import { useDispatch, useSelector } from "react-redux";
import { openBill } from "../../store/globalSlice";
import { BILLER } from "../../utils/roles";


export default function MainMenu({ changeView }) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const rol = useSelector((state) => state.global.rol.id)

  const openCreateBill = () => {
    dispatch(openBill())
    changeView()
  }

  return (
    <nav>
      <NavLink className="link link--subtle" to="/" onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t('menu.bills')}</b>
        </Text>
      </NavLink>
      {rol !== BILLER &&
        <NavLink className="link link--subtle" to="import" onClick={changeView}>
          <Text tagName="p" bodySize="l" >
            <b>{t('menu.importFile')}</b>
          </Text>
        </NavLink>
      }
      <NavLink className="link link--subtle" onClick={() => openCreateBill()}>
        <Text tagName="p" bodySize="l" >
          <b>{t('menu.createBill')}</b>
        </Text>
      </NavLink>
      
      <NavLink className="link link--subtle" to="breakdown" onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t('menu.breakdown')}</b>
        </Text>
      </NavLink>
      

      {rol !== BILLER &&
        <>
          <Text tagName="p" bodySize="xl" >
            <b>{t("menu.configuration")}</b>
          </Text>
          <div className={style.submenu}>
            <Link className="link link--subtle" to="services" onClick={changeView}>
              <Text tagName="p" bodySize="l" >
                {t('menu.services')}
              </Text>
            </Link>
            <Link className="link link--subtle" to="comments" onClick={changeView}>
              <Text tagName="p" bodySize="l" >
                {t('menu.comments')}
              </Text>
            </Link>
            <Link className="link link--subtle" to="states" onClick={changeView}>
              <Text tagName="p" bodySize="l" >
                {t('menu.states')}
              </Text>
            </Link>
            <Link className="link link--subtle" to="users" onClick={changeView}>
              <Text tagName="p" bodySize="l" >
                {t('menu.users')}
              </Text>
            </Link>
          </div>
        </>
      }
    </nav>
  )
}
