import FormField from "@ingka/form-field";
import TextArea from "@ingka/text-area";
import style from "./textArea.module.scss"

export default function Textarea({formField, textArea}) {
  return (
    <FormField
      {...formField}
      >
      <TextArea
        className={style.textarea}
        {...textArea}
      />
    </FormField>
  )
}
