import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: localStorage.getItem("language"),
  login: !!localStorage.getItem("login"),
  token: localStorage.getItem("token") || null,
  user: JSON.parse(localStorage.getItem("user")),
  rol: JSON.parse(localStorage.getItem("rol")),
  toasts: [],
  path: localStorage.getItem("path"),
  page: localStorage.getItem("page"),
  bill: JSON.parse(localStorage.getItem("bill")),
  states: JSON.parse(localStorage.getItem("states")),
  typeOrders: JSON.parse(localStorage.getItem("typeOrders")),
  needReload: false,
};
const BILLS_PAGE = "bills"

export const globalSlice = createSlice(
  {
  name: "global",
  initialState,
  reducers: {
    
    doToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", state.token)
    },

    doLogin: (state,action) => {
      state.user = action.payload.user
      state.rol = action.payload.rol
      state.bill = {isOpen: false, billId:null}
      localStorage.setItem("user", JSON.stringify(state.user))
      localStorage.setItem("rol", JSON.stringify(state.rol))
      localStorage.setItem("bill", JSON.stringify(state.bill))
    },

    doCheckUser: (state) => {
      state.login = true
      localStorage.setItem("login", state.login)
    },

    doLogout: (state,action) => {
      localStorage.clear()
      if(state.toasts.length === 0){
        window.location.href = "login"
      } else {
        setTimeout(()=>{
          window.location.href = "login"
        },2000)
      }
      return initialState
    },

    changeLang: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", state.language)
    },

    createMsgToast: (state, action) => {
      state.toasts = [...state.toasts, action.payload]
    },
    deleteMsgToast: (state, action) => {
      state.toasts = state.toasts.slice(0, action.payload)
    },

    updatePath: (state, action) =>{
      state.path = action.payload
      localStorage.setItem("path", state.path)
    },

    updatePage: (state, action) =>{
      state.page = action.payload
      localStorage.setItem("page", state.page)
    },

    openBill: (state, action) =>{
      state.bill = {isOpen: true, billId:action.payload ? action.payload : null}
      localStorage.setItem("bill", JSON.stringify(state.bill))
    },
    closeBill: (state, action) =>{
      state.bill = {isOpen: false, billId:null}
      localStorage.setItem("bill", JSON.stringify(state.bill))
      if(action.payload && state.page === BILLS_PAGE){
        state.needReload = true
        localStorage.setItem("needReload", state.needReload)
      }
    },

    updateStates: (state, action) => {
      state.states = action.payload
      localStorage.setItem("states", JSON.stringify(state.states))
    },

    setTypeOrders: (state, action) => {
      state.typeOrders = action.payload
      localStorage.setItem("typeOrders", JSON.stringify(state.typeOrders))
    },

    changeNeedReload: (state, action) => {
      state.needReload = action.payload
      localStorage.setItem("needReload", state.needReload)
    },
  },
});

export const { 
  doToken,
  changeLang, 
  doLogin, 
  doLoginFinalize,
  doLogout,
  createMsgToast,
  deleteMsgToast,
  updatePath,
  updatePage,
  openBill,
  closeBill,
  updateStates,
  setTypeOrders,
  changeNeedReload,
  doCheckUser
} = globalSlice.actions;
export default globalSlice.reducer;