import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import Button from "../components/ingka/Button";
import { useEffect, useState, Fragment } from "react";
import Load from "../components/ingka/Load";
import Table from '../components/global/Table';
import { getBreakdown } from "../services/billServices";
import * as XLSX from "xlsx";
import ExportPeriod from "../components/global/ExportPeriod";
import { useDispatch, useSelector } from "react-redux";
import { createMsgToast } from "../store/globalSlice";
import { BILLER } from "../utils/roles";

export default function Breakdown() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const rol = useSelector((state) => state.global.rol.id)
  const [loading, setLoading] = useState(false)
  const [breakdown, setBreakdown] = useState(null)
  const [totals, setTotals]= useState(null)
  const columns = [
      {
        accessorKey: "name",
        header: t('global.state'),
        size: 300,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{t('breakdown.total').toUpperCase()}</Text>)
      },
      {
        accessorKey: "totalReturnInvoices",
        header: t('breakdown.countReturns'),
        size: 100,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => <span>{formatNumber(cell.getValue())}</span>,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{ formatNumber(totals?.returnQuantity)}</Text>)
      },
      {
        accessorKey: "totalSalesInvoices",
        header: t('breakdown.countSales'),
        size: 100,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => <span>{formatNumber(cell.getValue())}</span>,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{formatNumber(totals?.salesQuantity)}</Text>)
      },
      {
        accessorKey: "totalReturnHomeValue",
        header: t('breakdown.amountReturns'),
        size: 100,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => <span>{formatCurrency(cell.getValue())}</span>,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{formatCurrency(totals?.returnTotal)}</Text>)
      },
      {
        accessorKey: "totalSalesHomeValue",
        header: t('breakdown.amountSales'),
        size: 100,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => <span>{formatCurrency(cell.getValue())}</span>,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{formatCurrency(totals?.salesTotal)}</Text>)
      },
      {
        accessorKey: "difference",
        header: t('breakdown.difference'),
        size: 100,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
        Cell: ({ cell }) => <span>{formatCurrency(cell.getValue())}</span>,
        Footer: () => (<Text tagName="h3" headingSize="s" className="text-white">{formatCurrency(totals?.differenceTotal)}</Text>)
      },
    ]

  useEffect(() => {
    goToGetBreakdown()
  }, [])

  const formatCurrency = (value) => {
    return Number.isInteger(value) ? value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) : value.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' });
  }

  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  }

  const goToGetBreakdown = () => {
    setLoading(true);
    getBreakdown().then((response) => {
      if (response) {
        setBreakdown(response.content)
        setTotals(response.totals)
      }
    })
      .finally(() => setLoading(false));
  }

  const exportBreakdown = () => {
    const breakdownWithTotals = [...breakdown, 
      { 
        name: t('breakdown.total').toUpperCase(),
        totalReturnInvoices:  totals.returnQuantity,
        totalSalesInvoices: totals.salesQuantity,
        totalReturnHomeValue: totals.returnTotal,
        totalSalesHomeValue: totals.salesTotal,
        difference: totals.differenceTotal
      }
    ]
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(breakdownWithTotals);
    XLSX.utils.book_append_sheet(workbook, worksheet, t('menu.breakdown'));
    XLSX.writeFile(workbook, `${t('menu.breakdown')}.xlsx`, { compression: false });
    dispatch(createMsgToast({body: t('breakdown.downloadBreakdownSuccess'), variant:"positive"}))
  }

  return (
    <>
      <section className="container-header-button">
        <Text tagName="h2" headingSize="m">{t('menu.breakdown')}</Text>
        {rol !== BILLER &&
        <div>
          <Button
            type="secondary"
            text={t("breakdown.export")}
            className="ml-1"
            onClick={() => exportBreakdown()}
            disabled={breakdown ? false : true}
            qa="buttonExportBreakdown"
          />
          {breakdown && 
            <ExportPeriod />
          }
        </div>
        }
      </section>
      {loading &&
        <Load />
      }
      {!loading && !breakdown &&
        <Text tagName="p" headingSize="s" className="text-center">{t('breakdown.noBreakdown')}</Text>
      }
      {!loading && breakdown &&
        <section>
          <Text tagName="h3" headingSize="s" className="mb-0 text-blue">RCMP</Text>
          <Table
            columns={columns}
            data={breakdown}
            paintLines={false}
            enableSorting={false}
          />
        </section>
      }
    </>
  )
}
