import axios from "axios";
import { store } from "../store/store";
import { doLogout } from "../store/globalSlice";


export async function apiFetch({
  method,
  url, 
  data, 
  body = false, 
  contentType = "application/json",
  headerParams = true,
  api = process.env.REACT_APP_API
  }) {
  const lang = localStorage.getItem("language")
  const token = localStorage.getItem("token")
  const user = JSON.parse(localStorage.getItem("user"))
  const header = {
      "Content-Type": contentType,
      ...(headerParams ? {
        'Authorization': 'Bearer '+token,
        'originUser': user.nickName,
        "Accept-Language": lang,
      } : {})
    }

  try {
    let response
    response = await axios({
      method: method,
      url: api+url,
      ...body ? { data: data } : { params: data },
      headers: header,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if(error.code === "ERR_NETWORK"){
      const axiosError = {
        response: error.name,
        message: error.message
      }
      return axiosError
    }
    if (error.response) {
      if (error.response.status === 422) {
        store.dispatch(doLogout());
        return {
          response: error.code,
          message: error.response.data.message
        };
      }
      if (error.response.status === 404) {
        window.location.href = "/not-found";
      }
      if(error.response.status === 409){
        return {
          response: error.name,
          message:error.response.data.message
        }
      }
      if(error.response.status === 401){
        store.dispatch(doLogout());
        return {
          response: error.code,
          message: error.response.data.message
        };
      }
    }
    return error
  }
}