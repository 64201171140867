import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateState } from '../services/statesService';
import { updateStates } from "../store/globalSlice";
import Text from '../components/ingka/Text';
import Table from '../components/global/Table';
import Switch from '../components/ingka/Switch';
import Load from './../components/ingka/Load';


export default function States() {
  const [t] = useTranslation('global');
  const states = useSelector(state => state.global.states);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    { accessorKey: 'name', header: t('states.state'), size: 700 },
    {
      accessorKey: 'requiredComment', header: t('states.requiredComment'),
      size: 60,
      Cell: ({ row }) =>
        <section className='justify-content-center'>
          <Switch
            props={{
              defaultChecked: row.original.requiredComment,
              onChange: e => onStateChange(e.target.checked, row.original)
            }}
          />
        </section>
    }
  ];

  const onStateChange = (requiredComment, statusChange) => {
    setLoading(true);
    const modifiedStatus = {
      id: statusChange.id,
      name: statusChange.name,
      requiredComment: requiredComment
    }
    onUpdateState(modifiedStatus);
  }

  const onUpdateState = (modifiedStatus) => {
    updateState(modifiedStatus, t('states.successUpdate')).then(updatedState => {
      const updated = states.map(item => (
        item.id === updatedState.id ? updatedState : item
      ));
      dispatch(updateStates(updated));
    }).finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <Text tagName='h2' headingSize='m'>{t('states.title')}</Text>
      {loading &&
        <Load />
      }
      {!loading && columns && states &&
        <Table
          columns={columns}
          data={states}
          enableSorting={false}
          enablePagination={false}
        />
      }
    </Fragment>
  )
}
